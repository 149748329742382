import React from 'react';
import Slider from "@material-ui/core/Slider";
import "../css/Simulation.css"
import { withStyles } from "@material-ui/core";
import CurrencyInput from "react-currency-input";
import ErrorMessage from "./ErrorMessage"

const FancySlider = withStyles({
    root: {
        color: "RGB(51,141,205)",
        height: 8,
    },
    thumb: {
        height: 15,
        width: 15,
        backgroundColor: "RGB(51,141,205)",
        marginTop: -4,
        marginLeft: -7.5,
        '&:focus,&:hover,&$active': {
            boxShadow: '0 0 0.5rem rgba(0, 0, 0, 0.3)',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: "0.25rem",
        borderStartStartRadius: 0,
        borderStartEndRadius: 0,
    },
    rail: {
        color: '#b9b9b9',
        height: 8,
        borderRadius: "0.25rem",
        borderStartStartRadius: 0,
        borderStartEndRadius: 0,
    },
})(Slider);

let inputError = "";

class Simulation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 500,
            period: 0,
            step: 0,
            name: "",
            cpf: "",
            birthdate: "",
            email: "",
            merchantId: "got657kas",
            utm_source: typeof this.props.utm_source !== "undefined" ? this.props.utm_source : "",

            inputError: ""
        };

        this.validators = {
            name: function () {
                if (!this.value.match(/([a-zA-Z]+)( +[a-zA-Z]+)+/))
                    return "Por favor insira seu nome completo";
                else
                    return ""
            },

            birthdate: function () {
                if (!this.value.match(/(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/[12]\d{3}/))
                    return "Data de nascimento inválida";
                else if (parseInt(this.value.slice(this.value.length - 4, this.value.length)) > new Date().getFullYear())
                    return "Data de nascimento inválida";
                else
                    return ""
            },

            cpf: function () {
                if (!this.value.match(/^\d{11}$/))
                    return "CPF inválido";
                else
                    return ""
            },
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleSliderChange = (event, newValue) => {
        this.setState({ value: newValue });
    };

    nextPage = () => {
        this.setState({ step: 1 })
    };

    incrementValue = () => {
        this.setState(prevState => {
            if (prevState.value >= 49500) {
                return { value: 50000 }
            } else {
                return { value: prevState.value + 500 }
            }
        })
    };

    decrementValue = () => {
        this.setState(prevState => {
            if (prevState.value <= 500) {
                return { value: 500 }
            } else {
                return { value: prevState.value - 500 }
            }
        })
    };

    selectPeriod = (period) => {
        this.setState({ period: period })
    };

    usefulData = () => {
        return {
            name: this.state.name,
            cpf: this.state.cpf,
            birthdate: this.state.birthdate,
            email: this.state.email,
            merchantId: "got657kas",
            amount: this.state.value + "00",
            loanPeriod: this.state.period,
            utm_source: this.props.utm_source
        }
    };

    validateInputs = () => {
        const data = this.usefulData();
        for (let [key, value] of Object.entries(data)) {
            if (typeof this.validators[key] === 'function') {
                let error = this.validators[key].call({ value: value, state: this.state });
                if (error !== "") {
                    this.setState({ inputError: error });
                    inputError = error;
                    break;
                } else {
                    this.setState({ inputError: "" });
                    inputError = ""
                }
            }
        }
    };

    submitForm = (e) => {
        e.preventDefault();

        //this.validateInputs();

        if (inputError === "") {
            const data = this.usefulData();

            let params = [
            "merchantId=" + data.merchantId,
            "amount=" + data.amount,
            "loanPeriod=" + data.loanPeriod];

            if (typeof data.utm_source !== "undefined") {
                params.push("utm_source=" + data.utm_source)
            }

            let redirect = "";
            if (typeof process.env.REACT_APP_ENROLL_URL !== "undefined") {
                redirect = process.env.REACT_APP_ENROLL_URL + "?"
            } else {
                redirect = window.location.origin + "/enroll/?"
            }
            window.location.href = redirect + params.join("&");
        }
    };

    render() {
        return (
            <div className="row justify-content-center text-center bg-white mb-5">
                <div className="col simulation-box bg-white pl-0 pr-0">
                    <div className="row justify-content-center mb-5 ml-2 mr-2">
                        <p style={{fontSize: "16px"}} className="text-blue">Simulador de Empréstimo:<br/>
                            Comparamos e exibimos o melhor empréstimo para você (Taxas e Serviços)
                        </p>
                        <p style={{fontSize: "12px"}} className="text-blue text-justify">
                            Na hora de escolher qual a melhor linha de crédito, é importante pesquisar e comparar as diferentes modalidades. Afinal, as condições de pagamento podem variar bastante de uma para outra.
                        </p>
                    </div>

                    {this.state.step === 0 &&
                        <div>
                            <div className="row justify-content-center m-auto bg-light mb-0"
                                style={{ height: "50px", width: "100%" }}>
                            <i style={{ fontSize: "20px", marginTop: "15px", color: "RGB(51,141,205)", cursor: "pointer" }}
                                className="fas fa-minus ml-3 col-2"
                                onClick={this.decrementValue} />

                                <CurrencyInput thousandSeparator="."
                                className="col-6"
                                    readonly="readonly"
                                    precision="0"
                                    type="text"
                                    prefix="R$ "
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        textAlign: "center",
                                    }}
                                    min={1000}
                                    max={50000}
                                    step={500} />

                            <i style={{ fontSize: "20px", marginTop: "15px", color: "RGB(51,141,205)", cursor: "pointer" }}
                                className="fas fa-plus mr-3 col-2"
                                onClick={this.incrementValue} />
 
                            </div>
                            <div className="row ml-4 mr-4">

                                <FancySlider
                                    style={{ top: "-11px" }}
                                    defaultValue={1000}
                                    value={this.state.value}
                                    onChange={this.handleSliderChange}
                                    min={1000}
                                    max={50000}
                                    step={500}
                                />
                            </div>

                            <div className="row mb-1 justify-content-center">
                                    <button type="button"
                                        className="periodButton w-100 ml-3 mr-3 mb-3"
                                    style={{ background: `${this.state.period === 6 ? "#384268" : "RGB(127,127,127)"}` }}
                                        onClick={() => this.selectPeriod(6)}>
                                        06 meses
                                </button>
                           
                                    <button type="button"
                                        className="periodButton w-100 mb-3"
                                    style={{
                                        background: `${this.state.period === 12 ? "#384268" : "RGB(127, 127, 127)"}` }}
                                        onClick={() => this.selectPeriod(12)}>
                                        12 meses
                                </button>
                               
                                    <button type="button"
                                        className="periodButton w-100 ml-3 mr-3 mb-3"
                                style={{ background: `${this.state.period === 18 ? "#384268" : "RGB(127, 127, 127)"}` }}
                                        onClick={() => this.selectPeriod(18)}>
                                        18 meses
                                </button>
                               
                                    <button type="button"
                                        className="periodButton w-100 mb-3"
                                style={{ background: `${this.state.period === 24 ? "#384268" : "RGB(127,127,127)"}` }}
                                        onClick={() => this.selectPeriod(24)}>
                                        24 meses
                                </button>
                            </div>

                            <div className="row ml-2 mr-2">
                                <button type="button"
                                    className="col-12 send-button"
                                    onClick={this.submitForm}>
                                    CONTINUAR
                            </button>
                            </div>
                        </div>
                    }

                    {this.state.inputError !== "" && <ErrorMessage message={this.state.inputError} />}

                    <div className="row justify-content-center mb-5 mt-4 ml-2 mr-2">
                        <div className="col simulation-features text-left">
                            <ol className="mt-3">
                            <li><span className="text-blue">Dinheiro na sua conta em até 48h.</span></li>
                            <li><span className="text-blue">Pré-aprovação de crédito em segundos.</span></li>
                            <li><span className="text-blue">Maior chance de conseguir um empréstimo.</span></li>
                                <li><span className="text-blue">Consultamos diversas financeiras.</span></li>
                                </ol>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}

export default Simulation