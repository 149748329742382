import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./utils/routes";

class App extends Component {
    render() {
        return (
            <Router children={Routes} />
        );
    }
}

export default App;