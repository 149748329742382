import React from 'react'

function OfferCard(props) {
    const offerArray = props.response.offers;

    var colItems = [];

    if (typeof offerArray == 'undefined') {
        return(<div></div>)
    }
    for (var i = 0; i < offerArray.length; i++) {

        let amount = parseFloat(offerArray[i].totalFinancedValue);
        amount = amount.toFixed(2).toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        colItems.push(

                <div className="offer-card mb-3" key={i}>
                    <div className="row">
                        <div className="col-12 mt-3">
                            {props.response.institution.bankName}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="w-100 offer-line mt-2 mb-3"/>
                        </div>
                    </div>

                    <div>
                        <div className="row">
                            <div className="col-12 h3">
                                {offerArray[i].installmentAmount}x
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 h2">
                                <strong>R$ {offerArray[i].installmentValue.toFixed(2).toString().replace(".",",")}</strong>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                1o vencimento: <strong>{`${offerArray[i].firstInstallmentDate.substring(8,10)}/${offerArray[i].firstInstallmentDate.substring(5,7)}/${offerArray[i].firstInstallmentDate.substring(0,4)}`}</strong>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12">
                            {offerArray[i].offerDescription}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 text-center mb-3">
                            <button onClick={props.confirmContractAndRedirect}
                                    type="button" name={i}
                                    className="offer-button">
                                <div style={{ "margin": "auto" }}>
                                    <span>FINALIZAR PEDIDO</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
        );
    }


    return (
        <div className="row justify-content-center">
            {colItems}
        </div>
    );
}

export default OfferCard