var UserProfile = (function() {
    var id = "";
    var fingerprint = ""

    var getId = function() {
        return id; // Or pull this from cookie/localStorage
    };

    var setId = function(name) {
        id = name;
        // Also set this in cookie/localStorage
    };

    var getFingerprint = function() {
        return fingerprint; // Or pull this from cookie/localStorage
    };

    var setFingerprint = function(name) {
        fingerprint = name;
        // Also set this in cookie/localStorage
    };

    return {
        getId: getId,
        setId: setId,
        setFingerprint: setFingerprint,
        getFingerprint: getFingerprint
    }

})();

export default UserProfile;