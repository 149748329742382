import React from 'react'
import ReactModal from "react-modal";

function ContractModal(props) {

    return (
        <div
            isOpen={props.isOpen}
            className="iframe-container w-100 h-100"
            style={{
                overlay: {
                    zIndex: "999",
                    backgroundColor: 'rgba(0,0,0,0.5)',
                },
                content: {
                    position: 'absolute',
                    top: `${props.isMobile ? "0" : "30px"}`,
                    left: `${props.isMobile ? "0" : "25%"}`,
                    right: `${props.isMobile ? "0" : "25%"}`,
                    bottom: `${props.isMobile ? "0" : "30px"}`,
                    padding: '5px 10px 5px 5px',
                    border: '1px solid #a6aeb5',
                    borderRadius: '0.3rem',
                    WebkitOverflowScrolling: 'touch',
                }
            }}>
            <iframe
                scrolling='no'
                className="embed-responsive-item"
                src={process.env.REACT_APP_FRONT_URL + "/contract-sign?ownId=" + props.ownId + "&merchantId=" + encodeURIComponent(props.encryptedCredentials)}
                width="100%"
                height="100%"
                style={{border:"0px", minHeight:"1000px"}}
            >
            </iframe>

        </div>
    );
}

export default ContractModal