import React from 'react';
import Header from "./components/Header"
import TextField from "./components/TextField";
import CellphoneField from "./components/CellphoneMaskField";
import DateField from "./components/DateMaskField";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import ErrorMessage from "./components/ErrorMessage";
import { toast, ToastContainer } from 'react-toastify';
import Footer from "./components/Footer"
import IconSSL from "./images/ssl-fundo-transparente.png"
import IconLetsEncrypt from "./images/letsencrypt-logo.png"


class AutoSimuleCustom extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                errorMessage: "",
                step: 0,
                clientId: "",
                loading: false,
                orderValue: "R$ 10.000",
                conditionalValue: 0,
                name: "",
                email: "",
                dateOfBirth: "",
                cpf: "",
                phone: "",
                sellerEmail: "",
                searched: false,
                offers: [],
                credPartner: "",
                uuid: "",
                ownId: "",
                birthCity: "",
                birthState: "",
                rg: "",
                street: "",
                streetNumber: "",
                complement: "",
                district: "",
                city: "",
                state: "",
                zipcode: "",

                redirectUrl: "",

                path: "",
                credentials: "",
            }
        }

        componentDidMount() {
            this.setState({
                path: this.props.path,
                credentials: this.props.credentials
            })
            if (this.props.match.params.merchantName) {
                axios.get(process.env.REACT_APP_MANAGEMENT_URL + `/partners/search?stub=${this.props.match.params.merchantName}`)
                    .then(response => {
                        if (response.data.length === 0) {
                            throw new Error("no return")
                        } else {
                            this.setState({
                                path: this.props.match.params.merchantName,
                                credentials: response.data.credentials
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e)
                        this.props.history.push('/naoencontrada')
                    })
            }
        }

        handleCPFChange = (event) => {
            if (event.target.value.length <= 11) {
                this.handleInputChange(event);
            }
        };

        handleRGChange = (event) => {
            if (event.target.value.length <= 9) {
                this.handleInputChange(event);
            }
        };

        handleCEPChange = (event) => {
            if (event.target.value.length <= 8) {
                this.handleInputChange(event);
                let field = event.target.name;
                axios
                    .get("https://viacep.com.br/ws/" + event.target.value + "/json/")
                    .then(response => {
                        if (response.data.erro !== true) {
                            if (field === "zipcode") {
                                this.setState({
                                    street: response.data.logradouro,
                                    district: response.data.bairro,
                                    city: response.data.localidade,
                                    state: response.data.uf
                                })
                            }
                        }
                    })
                    .catch(error => { console.log(error) })
            }
        };

        nextStep = (e) => {
            if (typeof e !== "undefined") {
                e.preventDefault();
            }

            this.setState(prevState => {
                return { errorMessage: "", step: prevState.step + 1 }
            }, function() {
                window.scrollTo(0, 0);
                this.setState(this.state);
            });
        };

        previousStep = (e) => {
            if (typeof e !== "undefined") {
                e.preventDefault();
            }

            this.setState({
                errorMessage: "",
                clientId: "",
                loading: false,
                orderValue: "R$ 10.000",
                conditionalValue: 0,
                name: "",
                email: "",
                dateOfBirth: "",
                cpf: "",
                phone: "",
                searched: false,
                offers: [],
                credPartner: "",
                uuid: "",
                ownId: "",
                birthCity: "",
                birthState: "",
                rg: "",
                street: "",
                streetNumber: "",
                complement: "",
                district: "",
                city: "",
                state: "",
                zipcode: "",
                redirectUrl: ""
            });

            this.setState(prevState => {
                return { step: prevState.step - 1 }
            }, function() {
                window.scrollTo(0, 0);
                this.setState(this.state);
            })
        };

        checkoutPrepare = async() => {
            this.setState({ errorMessage: "", searched: false, clientId: "", uuid: "", offers: [] });

            let amount = 0;
            let value = this.state.orderValue.replace("R$ ", "").replace(".", "");
            if (!value || parseFloat(value) < 500) {
                this.setState({ errorMessage: "Valor da compra não deve ser menor que R$ 500,00" });
                return
            }

            if (!this.state.name) {
                this.setState({ errorMessage: "Insira o nome do cliente" });
                return
            }
            if (!this.state.email.match(/\S+@\S+\.\S+/)) {
                this.setState({ errorMessage: "Insira um email válido" });
                return
            }
            if (this.state.cpf.length !== 11) {
                this.setState({ errorMessage: "Insira um CPF válido" });
                return
            }

            let [full, cents] = value.split(',');
            if (!cents) {
                amount = parseInt(full) * 100
            } else if (cents.length === 1) {
                amount = parseInt(full + cents) * 10
            } else if (cents.length === 2) {
                amount = parseInt(full + cents)
            }

            if (!this.state.dateOfBirth.match(/(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/(19\d{2}|2\d{3})/)) {
                this.setState({ errorMessage: "Data de nascimento inválido" });
                return
            }
            let [day, month, year] = this.state.dateOfBirth.split('/', -1);
            if (!this.state.phone.match(/[(]([1-9]{2})[)] (9[1-9]\d{3}-\d{4})/)) {
                this.setState({ errorMessage: "Número de celular inválido" });
                return
            }
            let [_, ddd, number] = this.state.phone.match(/[(]([1-9]{2})[)] (9[1-9]\d{3}-\d{4})/);

            if (!day || !month || !year) {
                this.setState({ errorMessage: "Data de nascimento inválido" });
                return
            }

            if (!ddd || !number) {
                this.setState({ errorMessage: "Número de celular inválido" });
                return
            }

            let data = {
                name: this.state.name,
                email: this.state.email,
                dateOfBirth: `${year}-${month}-${day}`,
                cpf: this.state.cpf,
                phone: {
                    countryCode: 55,
                    areaCode: parseInt(ddd),
                    number: parseInt(number.replace('-', ''))
                },
                amount: {
                    total: amount
                }
            };

            try {
                this.setState({ loading: true });
                let response = await axios.post(process.env.REACT_APP_CHECKOUT_URL + "/prepare", data, { auth: this.state.credentials });
                if (response && response.data && response.data.offers && response.data.offers.length > 0 && response.data.offers[0].uuid && response.data.offers[0].uuid.split('_').length === 3) {
                    var offers = response.data.offers
                    offers.sort((a, b) => a.numberOfInstallments - b.numberOfInstallments)
                    this.setState({
                        credPartner: response.data.institution.bankName,
                        clientId: response.data.offers[0].uuid.split('_')[0],
                        uuid: response.data.uuid,
                        offers: offers,
                        conditionalValue: response.data.conditionalValue
                    })
                }
                this.setState({ searched: true, loading: false });
            } catch (e) {
                this.setState({ errorMessage: "Erro ao verificar crédito do cliente" });
                this.setState({ loading: false });
                console.log(e);
            }
        };

        updateClientAndCreateLink = async() => {
            if (!this.state.ownId || !this.state.birthCity || !this.state.birthState || !this.state.rg) {
                this.setState({ errorMessage: "Insira os dados do cliente para continuar" });
                return
            }

            if (!this.state.street || !this.state.streetNumber || !this.state.district || !this.state.city || !this.state.state || !this.state.zipcode) {
                this.setState({ errorMessage: "Insira o endereço de cobrança do cliente para continuar" });
                return
            }

            let billingAddress = {
                street: this.state.street,
                streetNumber: this.state.streetNumber,
                complement: this.state.complement,
                district: this.state.district,
                city: this.state.city,
                state: this.state.state,
                country: "BRA",
                zipCode: this.state.zipcode,
            };
            let client = {
                idDocument: {
                    number: this.state.rg,
                },
                birthState: this.state.birthState,
                birthCity: this.state.birthCity,
                addresses: [
                    {...billingAddress, type: "BILLING" },
                    {...billingAddress, type: "SHIPPING" }
                ]
            };

            try {
                this.setState({ errorMessage: "" });
                await axios.post(process.env.REACT_APP_CONTRACT_URL + "/update/" + this.state.clientId, client, { auth: this.state.credentials });
                this.setState({ redirectUrl: `${window.location.origin}/simule/${this.state.path}/${this.state.uuid}?orderId=${this.state.ownId}&seller=${btoa(this.state.sellerEmail)}` });
                toast("Pré cadastro pronto, o link do cliente foi gerado!", {
                    toastId: 2,
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: false,
                    bodyClassName: "toast-container"
                });
            } catch (e) {
                console.log(e);
                this.setState({ errorMessage: "Erro ao fazer pré cadastro" });
            }
        };

        handleInputChange = (event) => {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            this.setState({
                [name]: value
            });
        };

        render() {
            return (
            <LoadingScreen
            loading={this.state.loading}
            bgColor="rgba(255,255,255,1)"
            spinnerColor="#10d37c"
            textColor="#676767"
            logoSrc="https://moneri.s3-sa-east-1.amazonaws.com/Pagdividido/Imagens/Logo-PagDividido-NovaCor.png"
            text="Processando...">

            <div>
                <ToastContainer />
                <Header/>
                <div className="wrapper" style={{marginBottom: '50px'}}>
                    <div style={{"display": `${(this.state.step === 0) ? "flex" : "none"}`, justifyContent: 'center'}} className="row ml-3 mr-3">
                        <div className="col form-container mt-1">
                            {this.state.searched && this.state.offers.length === 0 &&
                            <section className="text-white" style={{borderRadius: '18px', background: "#10d37c"}}>
                                <p style={{fontWeight: "500", fontSize: "1.6rem", padding: "16px 16px 0 16px", marginBottom: "0"}}>Não conseguimos</p>
                                <p style={{fontSize: "1.1rem", padding: "0 16px 16px 16px"}}>
                                    Infelizmente neste momento não foi possível encontrar uma linha de crédito para este CPF
                                </p>
                            </section>
                            }
                            {this.state.searched && this.state.offers.length > 0 &&
                            <section className="mb-5" style={{borderRadius: '5px', background: "#dcdcdc"}}>
                                <h3 className="p-3" style={{color: "#10d37c"}}>Parabéns Ronnie!</h3>
                                <p style={{fontSize: "1.1rem", padding: "0 16px 16px 16px"}}>
                                Conseguimos um pré-oferta de crédito para este CPF. Total pré-aprovado:
                                </p>
            
                                {this.state.conditionalValue && <center><h3 style={{color:"#0070C0"}}>R$  {this.state.conditionalValue},00  </h3></center>}
                                {!this.state.conditionalValue && <center><h3 style={{color:"#0070C0"}}>{this.state.orderValue}</h3></center>}

                                <p style={{fontSize: "1.1rem", padding: "0 16px 16px 16px"}}>
                                Por favor, no momento de concluir a compra, escolha a <span style={{color: "#10d37c"}}>Pag Dividido</span> como método de pagamento.
                                </p>

                            </section>
                            }
                            <section className="mb-5">
                                <TextField
                                    type="text"
                                    name="name"
                                    id="nameField"
                                    label="Nome completo"
                                    value={this.state.name}
                                    onChange={this.handleInputChange}/>
                                <TextField
                                    type="email"
                                    name="email"
                                    id="emailField"
                                    label="Email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}/>
                                <CellphoneField
                                    name="phone"
                                    id="cellphoneField"
                                    label="Telefone Celular"
                                    value={this.state.phone}
                                    onChange={this.handleInputChange}/>
                                <TextField
                                    type="text"
                                    name="cpf"
                                    id="cpfField"
                                    label="CPF (Somente Números)"
                                    pattern="[0-9]*"
                                    value={this.state.cpf}
                                    onChange={this.handleCPFChange}/>
                                <DateField
                                    name="dateOfBirth"
                                    id="birthdateField"
                                    label="Data de Nascimento"
                                    value={this.state.dateOfBirth}
                                    onChange={this.handleInputChange}/>
                                <button
                                    onClick={this.checkoutPrepare}
                                    className="row align-content-between send-button"
                                    style={{width: '100%', maxWidth: '400px'}}>
                                    <div style={{"margin": "auto"}}>
                                        <span>VERIFICAR CRÉDITO</span>
                                    </div>
                                </button>

                                {this.state.errorMessage &&
                                <ErrorMessage message={this.state.errorMessage} />}
                            </section>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                    <div className="col-2 text-center mb-5">
                    <img src={IconSSL} alt="SSL"/>

                        </div>
                        <div className="col-2 text-center mb-5">
                        <img src={IconLetsEncrypt} alt="Letencrypt" />

                        </div>
                        </div>
                </div>
                <Footer />
            </div>
        </LoadingScreen>
                )
            }
        }


        export default AutoSimuleCustom