import React from 'react';
import "react-step-progress-bar/styles.css";
import "./css/ClientPage.css";
import './css/bootstrap.min.css';
import './css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Header from "./components/Header"

class ErrorPage extends React.Component {
    render() {
        return (
            <div>
                <div>
                    <Header/>
                </div>
                <div style={{width: "100%", height: "1px", "background-color": "#c2c2c2"}}/>
                <div className="text-center mt-3">
                    <p className="h3" >Página não encontrada</p>
                </div>
            </div>
        );
    }
}

export default ErrorPage
