import * as React from "react";
import { Route, Switch } from "react-router-dom";
import ClientPage from "../ClientPage"
import Simule from "../Simule"
import SimuleCustom from "../SimuleCustom"
import AutoSimuleCustom from "../AutoSimuleCustom"
import SimuleCustomCliente from "../SimuleCustomCliente"
import Zarpo from "../Zarpo"
import ErrorPage from "../ErrorPage"
import NotFoundPage from "../NotFoundPage"
import '../index.css';


export const Routes = (
    <Switch>
        <Route exact path="/enroll/:orderId?" component={ClientPage} />
        <Route exact path="/simule" component={Simule} />
        <Route exact path="/simule/wier" render={(props) => <SimuleCustom {...props} path={'wier'} credentials={{username: process.env.REACT_APP_WIER_USERNAME, password: process.env.REACT_APP_WIER_PASSWORD}}/>} />
        <Route exact path="/simule/wier/:uuid" render={(props) => <SimuleCustomCliente {...props} name={'Wier'} credentials={{username: process.env.REACT_APP_WIER_USERNAME, password: process.env.REACT_APP_WIER_PASSWORD}}/>} />
        <Route exact path="/simule/ecadeiras" render={(props) => <SimuleCustom {...props} path={'ecadeiras'} credentials={{username: process.env.REACT_APP_ECADEIRAS_USERNAME, password: process.env.REACT_APP_ECADEIRAS_PASSWORD}}/>} />
        <Route exact path="/simule/ecadeiras/:uuid" render={(props) => <SimuleCustomCliente {...props} name={'eCadeiras'} credentials={{username: process.env.REACT_APP_ECADEIRAS_USERNAME, password: process.env.REACT_APP_ECADEIRAS_PASSWORD}}/>} />
        <Route exact path="/simule/cianet" render={(props) => <SimuleCustom {...props} path={'cianet'} credentials={{username: process.env.REACT_APP_CIANET_USERNAME, password: process.env.REACT_APP_CIANET_PASSWORD}}/>} />
        <Route exact path="/simule/cianet/:uuid" render={(props) => <SimuleCustomCliente {...props} name={'Cianet'} credentials={{username: process.env.REACT_APP_CIANET_USERNAME, password: process.env.REACT_APP_CIANET_PASSWORD}}/>} />
        <Route exact path="/simule/autosul" render={(props) => <SimuleCustom {...props} path={'autosul'} credentials={{username: process.env.REACT_APP_RHINO_USERNAME, password: process.env.REACT_APP_RHINO_PASSWORD}}/>} />
        <Route exact path="/simule/autosul/:uuid" render={(props) => <SimuleCustomCliente {...props} name={'Auto Sul'} credentials={{username: process.env.REACT_APP_RHINO_USERNAME, password: process.env.REACT_APP_RHINO_PASSWORD}}/>} />
        <Route exact path="/zarpo" component={Zarpo} /> {/*Front para geracao do link de cadastro do cliente Zarpo*/}
        <Route exact path="/simule/:merchantName" render={(props) => <SimuleCustom {...props} path={'wier'} credentials={{username: process.env.REACT_APP_WIER_USERNAME, password: process.env.REACT_APP_WIER_PASSWORD}}/>} />
        <Route exact path="/auto-simule/:merchantName" render={(props) => <AutoSimuleCustom {...props} path={'wier'} credentials={{username: process.env.REACT_APP_WIER_USERNAME, password: process.env.REACT_APP_WIER_PASSWORD}}/>} />
        <Route exact path="/simule/:merchantName/:uuid" render={(props) => <SimuleCustomCliente {...props}/>} />
        <Route exact path="/erro" component={ErrorPage} />
        <Route exact path="/naoencontrada" component={NotFoundPage} />
    </Switch>
);
