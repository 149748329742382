import React from 'react';
import "../css/Simulation.css"
import ErrorMessage from "./ErrorMessage"
import TextField from "./TextField";
import CurrencyField from "./CurrencyField";
import CellphoneField from "./CellphoneMaskField";
import axios from "axios";

let inputError = "";

class Simulation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            period: 0,
            step: 0,
            name: "",
            cpf: "",
            cellPhone: "",
            email: "",
            redirectUrl: "",
            merchantId: "MCXOVI-6714",
            orderId: "",

            inputError: "",

            checkoutAuthData: {
                username: process.env.REACT_APP_CHECKOUT_USERNAME,
                password: process.env.REACT_APP_CHECKOUT_PASSWORD
            },
        };

        this.validators = {
            name: function () {
                if (!this.value.match(/([a-zA-Z]+)( +[a-zA-Z]+)+/))
                    return "Por favor insira seu nome completo";
                else
                    return ""
            },

            email: function () {
                if (this.state.step !== 0) {
                    return "";
                }
                if (!this.value.match(
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
                    return "Por favor insira um e-mail válido";
                else
                    return ""
            },
            cellPhone: function () {

                if (this.state.step !== 0) {
                    return "";
                }
                if (!this.value.match(/[(][1-9]{2}[)] 9[1-9]\d{3}-\d{4}/))
                    return "Telefone celular inválido";
                else
                    return ""
            },
            amount: function () {

                if (this.state.step !== 0) {
                    return "";
                }
                if (parseInt(this.value.replace(/[R\$.,]/g, "")) > 5000000) 
                    return "Valor máximo R$ 50.000,00";
                
                else
                    return ""
            }
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    incrementValue = () => {
        this.setState(prevState => {
            if (prevState.value >= 49500) {
                return { value: 50000 }
            } else {
                return { value: prevState.value + 500 }
            }
        })
    };

    decrementValue = () => {
        this.setState(prevState => {
            if (prevState.value <= 500) {
                return { value: 500 }
            } else {
                return { value: prevState.value - 500 }
            }
        })
    };

    selectPeriod = (period) => {
        this.setState({ period: period })
    };

    usefulData = () => {
        return {
            name: this.state.name,
            cpf: this.state.cpf,
            cellPhone: this.state.cellPhone,
            email: this.state.email,
            merchantId: "MCXOVI-6714",
            amount: this.state.value + "00",
            loanPeriod: this.state.period
        }
    };

    validateInputs = () => {
        const data = this.usefulData();
        for (let [key, value] of Object.entries(data)) {
            if (typeof this.validators[key] === 'function') {
                let error = this.validators[key].call({ value: value, state: this.state });
                if (error !== "") {
                    this.setState({ inputError: error });
                    inputError = error;
                    break;
                } else {
                    this.setState({ inputError: "" });
                    inputError = ""
                }
            }
        }
    };

    submitForm = (e) => {
        e.preventDefault();

        this.validateInputs();

        if (inputError === "") {
            const data = this.usefulData();

            let cellphone = data.cellPhone.replace(/[\)\(-]/g, "");
            let areaCode = parseInt(cellphone.substring(0, 2));
            let number = parseInt(cellphone.substring(2));

            let body = {
                amount: {
                    total: parseInt(data.amount.replace(/[R\$.,]/g,""))
                },
                merchant: {
                    id: "MCXOVI-6714",
                    name: "Zarpo"
                },
                customer: {
                    fullName: data.name,
                    email: data.email,
                    phone: {
                        countryCode: 55,
                        areaCode: areaCode,
                        number: number
                    },
                    taxDocument: {
                        type: "CPF",
                        number: data.cpf.replace(/[.-]/g, "")
                    }
                }
            };

            axios
                .post(process.env.REACT_APP_CHECKOUT_URL, body,{auth: this.state.checkoutAuthData})
                .then((response) => {
                    console.log("Create Order success");

                    let redirect = "";
                    if (typeof process.env.REACT_APP_ENROLL_URL !== "undefined") {
                        redirect = process.env.REACT_APP_ENROLL_URL
                    } else {
                        redirect = window.location.origin + "/enroll/"
                    }

                    this.setState({orderId: response.data.orderId, redirectUrl: redirect + response.data.orderId},function () {
                        //window.location.href = this.state.redirectUrl;
                    });
                })
                .catch(error => {
                    console.log("Create Order failed");
                    console.log(error)});

            let params = ["name=" + encodeURIComponent(data.name),
                "cpf=" + data.cpf.replace(/[.-]/g, ""),
                "cellPhone=" + data.cellPhone.replace(/[\)\(-]/g, ""),
            "email=" + data.email,
            "merchantId=" + data.merchantId,
            "amount=" + data.amount.replace(/[R\$.,]/g,""),
            ];
        }
    };

    render() {
        return (
            <div className="row justify-content-center text-center bg-white mb-5">
                <div className="col simulation-box bg-white pl-0 pr-0">
                    
                    {this.state.step === 0 &&
                        <div>
                            <div className="row mt-3 justify-content-center">
                            <form onSubmit={this.submitForm}>

                                <CurrencyField
                                    name="value"
                                    id="amountField"
                                    label="Valor do Crédito"
                                    value={this.state.value}
                                    onChange={this.handleChange} />

                                    <TextField
                                        type="text"
                                        name="name"
                                        id="nameField"
                                        label="Nome Completo"
                                        required
                                        value={this.state.name}
                                        onChange={this.handleChange} />

                                <CellphoneField
                                    onChange={this.handleChange}
                                    name="cellPhone"
                                    id="cellPhoneField"
                                    label="Telefone celular"
                                    value={this.state.cellPhone}
                                />

                                    <TextField
                                        type="text"
                                        name="email"
                                        id="emailField"
                                        label="Email"
                                        required
                                        value={this.state.email}
                                        onChange={this.handleChange} />


                                    <button className="col-12 advanceButton text-center mt-3" type="submit">
                                        <div style={{ margin: "auto" }}>
                                            <span>SOLICITAR</span>
                                        </div>
                                    </button>
                                </form>
                            </div>
                        </div>
                    }

                    {this.state.inputError !== "" && <ErrorMessage message={this.state.inputError} />}

                    {(this.state.redirectUrl != "") ? (
                        
                        <div className="row justify-content-center mb-5 mt-4">
                            <div className="col simulation-features text-left">
                                {this.state.redirectUrl}
                            </div>

                        </div>
                    ): true 
                    }

                </div>
            </div>
        );
    }
}

export default Simulation