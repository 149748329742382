import MaskedInput from 'react-text-mask'
import React from "react";
import { withStyles } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ReactDOM from 'react-dom';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const numberMask = createNumberMask({
    prefix: 'R$ ',
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',',
    allowDecimal: true,
    decimalLimit: 2
});

const styles = ({
    root: {
        marginTop: "16px",
        marginBottom: "8px",
        '.MuiInputBase-input': {
            "font-family": 'Montserrat',
        },
        '& input': {
            "color": "#384268"
        },
        '& .MuiInputBase-root': {
            width: "400px"
        },
        '& label': {
            background: "white",
            left: "14px",
            top: "-4px",

        },
        '& label.Mui-focused': {
            color: '#10d37c',
            background: "white",
        },
        '& .MuiInputLabel-shrink': {
            background: "white",
        },
        '& .MuiOutlinedInput-root': {
            width: "400px",
            '@media (max-width: 727px)': {
                width: '100%'
            },
            '& fieldset': {
                borderColor: '#c4c4c4',
                borderWidth: "2px",
                borderRadius: "10px",
            },
            '&:hover fieldset': {
                borderColor: '#c4c4c4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#10d37c',
                borderWidth: "2px",
                borderRadius: "10px",
                notched: true
            },
        },
    },
});

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide={false}
            autoComplete="off"
            mask={numberMask}
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

class FormattedInputs extends React.Component {

    render() {
        const { classes, onChange, id, label, name, value } = this.props;

        return (
            <div>
                <FormControl  className={classes.root}>
                    <InputLabel
                        htmlFor={id}
                        ref={ref => {this.labelRef = ReactDOM.findDOMNode(ref);}}>{label}</InputLabel>
                    <OutlinedInput
                        labelWidth={this.labelRef ? this.labelRef.offsetWidth : 0}
                        fullWidth
                        id={id}
                        name={name}
                        onChange={onChange}
                        value={value}
                        inputComponent={TextMaskCustom}
                    />
                </FormControl>
            </div>
        )
    }

}

export default withStyles(styles)(FormattedInputs);