import React from 'react';
import Header from "./components/Header"
import Footer from "./components/Footer"
import Simulation from './components/Simulation';
import qs from "query-string";

const baseLogoUrl = "https://pigpeg-dev.s3.amazonaws.com/logo.";
const baseLogoFileType = ".png";
var merchantLogoUrl;


class Simule extends React.Component {

    returnUtm = () => {
        return qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).utm_source
    };

    componentDidMount() {
        if (typeof this.props.match.params.merchantName != 'undefined') {
            merchantLogoUrl = baseLogoUrl + this.props.match.params.merchantName + baseLogoFileType;
        }
    }
    render() {
        if (typeof this.props.match.params.merchantName != 'undefined') {
            merchantLogoUrl = baseLogoUrl + this.props.match.params.merchantName + baseLogoFileType;
        }

        return (
            <div>
                <Header merchantLogoUrl={merchantLogoUrl}/>
                <Simulation utm_source={this.returnUtm()}/>
                <Footer />
            </div>

        )
    }
}


export default Simule