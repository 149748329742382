import React, { Component } from 'react';
import "../css/Header.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

class Header extends Component {


    notify() {
        toast("Atenção! A PagDividido não solicita depósito antecipado para a liberação do empréstimo. Isso é crime.",
            {
                toastId: 1,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: false,
                bodyClassName: "toast-container"
            }
        )
    }

    render() {
        const merchantLogoUrl = this.props.merchantLogoUrl;
        return (
            <div onLoad={this.notify}>
                <section className="header-area" id="about">
                    <div className="row justify-content-center">
                        <div className="col col-lg-2 align-self-center">
                            <img src="https://pagdivididostatic.blob.core.windows.net/static/logo-padrao-verdepreto.svg"
                                 width="100%"  alt="PagDividido" />
                        </div>
                        <div className="col col-lg-2 offset-lg-8">
                            <img src="https://pagdivididostatic.blob.core.windows.net/static/ssl-seguro.svg"
                                 width="100%" alt="SSLSeguro" />
                        </div>
                    </div>
                  {/*
                    <div className="row justify-content-center">
                        <div className="col img-container">
                            <img src={logoLinha} alt="PagDividido"/>
                        </div>
                    </div>*/
                  }
                </section>
                <div className="row justify-content-center mb-3">
                    <div className="col img-partner-container text-center">
                        {(typeof merchantLogoUrl == 'undefined') ?
                            true :
                            <img src={merchantLogoUrl} alt="Parceiro" width="20%" style={{ "maxWidth": "250px" }} style={{ "left": "0" }} />}

                    </div>
                </div>
            </div>
        );
    }
}

export default Header