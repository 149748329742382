import React from 'react'
import ReactModal from "react-modal";

function TermsModal(props) {
    return (
        <ReactModal
            isOpen={props.isOpen}
            style={{
                overlay: {
                    zIndex: "999",
                    backgroundColor: 'rgba(0,0,0,0.5)',
                },
                content: {
                    position: 'absolute',
                    top: `${props.isMobile ? "0" : "30px"}`,
                    left: `${props.isMobile ? "0" : "25%"}`,
                    right: `${props.isMobile ? "0" : "25%"}`,
                    bottom: `${props.isMobile ? "0" : "30px"}`,
                    padding: '5px 10px 5px 5px',
                    border: '1px solid #a6aeb5',
                    borderRadius: '0.3rem',
                    WebkitOverflowScrolling: 'touch',
                }
            }}>

            <div className="modal-header">
                <h3 className="modal-title" id="porqueDados">Termos e condições de uso </h3>
                <button type="button" onClick={props.handleToggleModal} className="close" aria-label="Close" style={{"cursor": "pointer"}}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="modal-body text-justify" style={{ "color": "#000000" }}>
                <p style={{ "textJustify": "auto" }}>
                    O endereço eletrônico https://www.pagdividido.com.br/ (“Site”) é propriedade de PAG DIVIDIDO TECNOLOGIA LTDA., inscrita no CNPJ sob o nº 32.583.202/0001-08 (“Sociedade”). O acesso e o uso deste Site estão sujeitos a estes Termos e Condições de Uso (“Termo de Uso”) e a Política de Privacidade, disponível através do seguinte link https://www.pagdividido.com.br/.
Ao acessar e usar o Site, qualquer pessoa, física ou jurídica, (“Usuário”) aceita todas as condições deste Termo de Uso e da Política de Privacidade. Caso o Usuário não concorde com alguma condição destes instrumentos, o acesso e o uso deste Site devem ser imediatamente interrompidos.
Este Termo de Uso define os direitos e obrigações do Usuário durante o acesso e o uso do Site.


                </p>
                <p>
                    <span className="font-weight-bold text-dark">I – Conteúdo deste Site</span>
                </p>
                <p className="text-justify">
                    A Sociedade reserva-se o direito de, a qualquer momento, sem aviso prévio, modificar, alterar, acrescentar ou
                    remover o conteúdo das páginas deste site, incluindo textos, imagens e fotografias, sendo que as novas versões
                    passarão a vigorar a partir do momento em que forem postadas no Site. Por isso o Usuário deve ler o Termo de Uso
                    e a Política de Privacidade sempre que visitar o Site.
                </p>
                <p>
                    <span className="font-weight-bold text-dark">II – Responsabilidade do Usuário</span>
                </p>
                <p className="text-justify">
                    É de inteira responsabilidade do Usuário: (i) providenciar o seu próprio acesso à Internet e pagar todas as
                    taxas de serviço eventualmente cobradas por terceiros com relação a tal acesso; (ii) providenciar seu próprio
                    acesso a um endereço para envio de mensagens eletrônicas e pagar todas as taxas de serviço eventualmente
                    cobradas por terceiros com relação a tal acesso; (iii) providenciar todo o equipamento necessário para efetuar
                    sua conexão à Internet, incluindo, mas não se limitando, a um computador e um modem; (iv) efetuar seu próprio
                    cadastro no Site, responsabilizando-se pela correção e veracidade dos dados informados, assim como pela guarda
                    de sua senha de acesso; (v) manter o ambiente de seu computador seguro, com uso de ferramentas disponíveis como
                    antivírus e firewall, entre outras, atualizadas, de modo a contribuir na prevenção de riscos eletrônicos do lado
                    do Usuário; (vii) o uso de material e/ou conteúdo que possa infringir o direito de terceiros; e (viii) o
                    conteúdo por ele disponibilizado neste Site, assegurando que o mesmo não viola quaisquer direitos de terceiros.
                    O Usuário concorda com a obrigação de indenizar a Sociedade em ação regressiva por eventuais prejuízos causados
                    à mesma, em decorrência de ações que envolvam atos do Usuário, incluindo os efeitos do Art. 70, III, do Código
                    de Processo Civil.

                </p>
                <p>
                    <span className="font-weight-bold text-dark">III – Propriedade Intelectual</span>
                </p>
                <p className="text-justify">
                    Este Site contém material de propriedade intelectual da Sociedade ou para ela licenciada. Este material inclui,
                    mas não está limitado ao design, disposição, aparência e gráficos. A reprodução é proibida, exceto em
                    conformidade com o aviso de direitos autorais, que faz parte deste Termo de Uso e da Política de Privacidade.
                    O Usuário assume toda e qualquer responsabilidade, de caráter civil e/ou criminal, pela utilização indevida das
                    informações, textos, gráficos, marcas, obras, e de todo e qualquer direito de propriedade intelectual ou
                    industrial deste Site. Qualquer utilização não contemplada na presente autorização será considerada como uma
                    violação dos direitos autorais e estará sujeita às sanções cabíveis na Lei 9.610/98, de 19 de fevereiro de 1998,
                    e suas eventuais alterações.

                </p>
                <p>
                    <span className="font-weight-bold text-dark">IV – Exclusão de Garantias e de Responsabilidade</span>
                </p>
                <p className="text-justify">
                    Em decorrência de questões operacionais e de terceirização de serviços, o Site e os serviços estão sujeitos a
                    eventuais problemas de interrupção, falha técnica, e/ou de indisponibilidade de funcionamento temporário. Embora
                    a Sociedade empregue seus melhores esforços na segurança de seus sistemas, considerando a vulnerabilidade e o
                    constante avanço da tecnologia de invasões, a Sociedade NÃO SE RESPONSABILIZA POR ATOS DE TERCEIROS QUE LOGREM
                    ÊXITO EM COLETAR OU UTILIZAR, POR QUAISQUER MEIOS, DADOS CADASTRAIS E INFORMAÇÕES DISPONIBILIZADAS PELO USUÁRIO
                    no Site.
                    Este Site também pode incluir esporadicamente links para outros websites. Tais links são publicados para sua
                    conveniência com o objetivo de fornecer mais informações. Eles não significam que nós endossamos o(s) site(s), e
                    igualmente não temos nenhuma responsabilidade pelo seu conteúdo.

                </p>
                <p>
                    <span className="font-weight-bold text-dark">V – Privacidade dos Usuários</span>
                </p>
                <p className="text-justify">
                    As informações de cadastro e demais informações sobre os Usuários estão sujeitas ao tratamento definido pela
                    Política de Privacidade, conforme instrumento disponibilizado no Site. Para maiores informações, favor consultar
                    o documento Política de Privacidade. Tal instrumento constitui parte integrante deste Termo de Uso e do endereço
                    eletrônico https://www.pagdividido.com.br/ e deverá ser lido atentamente por todos os Usuários antes da aceitação e
                    utilização do Site. O Usuário desde já concorda que a Sociedade poderá ceder ou divulgar informações pessoais,
                    incluídas nos conteúdos de seu Site, se vier a ser intimada, por decisão governamental ou judicial.
                    Ao aceitar estes Termos de Uso autorizo a PagDividido a comunicar me através de quaisquer meios eletrônicos, como
                    correio eletrônico, telefone, celular, mensagens de texto (SMS ou mensagens via aplicativos), e, inclusive,
                    correspondência física.
                    Este site faz uso da tecnologia de cookies e/ou semelhantes para fazer tracking dos usuários afim de aprimorar
                    as suas preferências e experiência de navegação.

                </p>
                <p>
                    <span className="font-weight-bold text-dark">VI – Rescisão</span>
                </p>
                <p className="text-justify">
                    Para promover o bom funcionamento e qualidade dos Serviços do Site, a Sociedade se reserva no direito de, sem a
                    necessidade de notificação prévia, impedir ou interromper o acesso do Usuário que, segundo a Sociedade, estiver
                    atuando de qualquer forma a violar qualquer disposição contida neste Termo de Uso ou na Política de Privacidade.
                </p>
                <p>
                    <span className="font-weight-bold text-dark">VII – Disposições Gerais</span>
                </p>
                <p className="text-justify">
                    A tolerância ao eventual descumprimento de quaisquer das cláusulas e condições do presente instrumento não
                    constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade das mesmas a
                    qualquer tempo. O presente Termo de Uso constitui acordo integral entre as partes envolvidas, prevalecendo sobre
                    qualquer outro entendimento firmado anteriormente.
                    O uso deste Site e qualquer litígio decorrente dessa utilização estão sujeitos às leis da República Federativa
                    do Brasil e dos tribunais da Comarca Central da Capital do Estado de São Paulo.
                </p>
                <p>
                    <h3>Política de privacidade</h3>
                </p>
                <p className="text-justify">
                    POR FAVOR, LEIA COM ATENÇÃO ESTA POLÍTICA DE PRIVACIDADE PARA CONHECER OS TERMOS E CONDIÇÕES EM QUE SUAS
                    INFORMAÇÕES PESSOAIS SERÃO ARMAZENADAS, UTILIZADAS E PROTEGIDAS EM NOSSO SITE. AO FORNECER INFORMAÇÕES PESSOAIS
                    OU NAVEGAR NO SITE, VOCÊ ESTARÁ AUTOMATICAMENTE CONCORDANDO COM AS REGRAS DE UTILIZAÇÃO, PROTEÇÃO E SEGURANÇA
                    AQUI ESTABELECIDAS.
                    A Sociedade não é uma instituição financeira, companhia de seguros, operadora de meios de pagamento ou bandeira
                    de cartão de crédito.

                    A Sociedade não cobra quaisquer valores diretamente dos usuários. Em alguns casos, poderemos receber remuneração
                    das Instituições Financeiras parceiras, anunciantes e/ou outros parceiros listados no Site. AS INSTITUIÇÕES
                    FINANCEIRAS PARCEIRAS PODERÃO EVENTUALMENTE COBRAR TAXAS DOS USUÁRIOS, SEM QUALQUER ENVOLVIMENTO COM A SOCIEDADE
                    OU COM O SITE.
                </p>
                <p>
                    <span className="font-weight-bold text-dark">I – Finalidade</span>
                </p>
                <p className="text-justify">
                    A solicitação de dados pessoais do Usuário pela Sociedade tem como finalidade, exclusivamente, a simulação e a
                    cotação a fim de intermediar a contratação de serviços e produtos das Instituições Financeiras parceiras da
                    Sociedade para os nossos Usuários. O nosso principal objetivo é gerar conveniência ao nosso Usuário no seu
                    processo de avaliação e contratação destes serviços e produtos (“Serviços”).
                </p>
                <p>
                    <span className="font-weight-bold text-dark">II – Coleta de informações</span>
                </p>
                <p className="text-justify">
                    Ao utilizar os Serviços de nosso Site, solicitaremos que o Usuário se identifique com o preenchimento de apenas
                    um cadastro ou proposta, sendo que o Usuário poderá receber respostas de várias Instituições Financeiras
                    parceiras, podendo comparar e contratar a condição que achar mais conveniente. O SITE NÃO GARANTE AO USUÁRIO
                    NENHUM SUCESSO DE APROVAÇÃO OU CONTRATAÇÃO DE CRÉDITO.
                    A identificação nada mais é que o preenchimento de um cadastro pessoal contendo campos obrigatórios e opcionais,
                    sendo que as informações coletadas visam possibilitar um tratamento especial e individualizado para cada
                    Usuário. Através dessas informações, buscamos aprimorar nosso canal de comunicação com o Usuário, conhecê-lo
                    melhor e prestar serviços que atendam às suas necessidades, expectativas e preferências.
                    Além das informações do cadastro, a Sociedade também pode armazenar dados sobre a contratação efetiva dos
                    Usuários com as Instituições Financeiras parceiras, e outros tipos de interação com o Site.
                </p>
                <p>
                    <span className="font-weight-bold text-dark">III – Formas de utilização</span>
                </p>
                <p className="text-justify">
                    Ao preencher os dados e anexar os documentos solicitados pelo Site, o Usuário sempre deverá prezar pela
                    veracidade e exatidão dos mesmos, sabendo que poderá responder civil ou criminalmente por informações indevidas
                    ou equivocadas. O Usuário autoriza o Site e seus parceiros a consultar informações adicionais sobre o próprio em
                    fontes e de naturezas diversas e a realizar contatos telefônicos com o próprio ou com terceiros por ele
                    indicados. O Usuário autoriza a Sociedade a usar estes dados e documentos para realizar avaliação do Perfil de
                    Crédito, confirmar a veracidade das informações prestadas e para outros usos necessários à prestação dos
                    produtos e serviços oferecidos. O USUÁRIO AUTORIZA O SITE A COMPARTILHAR OS DADOS E DOCUMENTOS NECESSÁRIOS PARA
                    REALIZAR OS SERVIÇOS OFERECIDOS COM SEUS PARCEIROS (INSTITUIÇÕES FINANCEIRAS E PARCEIROS OPERACIONAIS).
                    Suas informações serão usadas, ainda, para estatísticas sobre o seu perfil e de outros internautas que acessam o
                    Site, permitindo o desenvolvimento de produtos e serviços personalizados.
                    O Site realizará, a critérios próprios e de forma isenta, uma avaliação das informações, documentos e
                    referências prestadas pelo Usuário ou obtidas adicionalmente para definição do Perfil de Crédito, que
                    corresponde a uma qualificação do Site quanto ao seu perfil de risco de crédito. O Site associa o Perfil de
                    Crédito à uma faixa de taxas de juros praticadas nos empréstimos oferecidos pelas instituições financeiras em
                    geral para estimar a probabilidade do Usuário ter sua solicitação de crédito aprovada. Este critério de
                    avaliação poderá ser revisto a qualquer momento o que poderá implicar em alteração do Perfil de Crédito do
                    Usuário sem aviso prévio. O Site não é obrigado a divulgar nenhum detalhe do seu critério de avaliação. Pela
                    avaliação e uso do Perfil de Crédito, o Site não poderá ser responsabilizado civil ou criminalmente, tampouco
                    estará sujeito a indenização ou ressarcimento por solicitação do Usuário. O Perfil de Crédito corresponde apenas
                    a uma estimativa do Site sobre a faixa mais provável de ofertas de taxas de juros que o Usuário receberá das
                    Instituições Financeiras e, por ser uma estimativa, não garante que o Usuário terá seu crédito aprovado e
                    tampouco nas condições estimadas pelo Site.
                </p>
                <p>
                    <span className="font-weight-bold text-dark">IV – Segurança e proteção (Criptografia de Dados)</span>
                </p>
                <p className="text-justify">
                    Suas informações serão armazenadas em um servidor seguro, e sempre que houver necessidade de identificação ou
                    fornecimento de informações sigilosas, a transmissão de dados entre seu computador e o Site será realizada
                    através de conexão segura.
                </p>
                <p>
                    <span className="font-weight-bold text-dark">V – Exclusão de Garantias e de Responsabilidade</span>
                </p>
                <p className="text-justify">
                    Não nos responsabilizamos pelos serviços prestados por terceiros, incluindo as Instituições Financeiras
                    parceiras, após o Usuário ser direcionado às mesmas através dos links contidos no Site o qual ocorrerá por conta
                    e risco do Usuário, inclusive os sites direcionados serão regidos pelos próprios Termos de Uso e Políticas de
                    Privacidade destes sites os quais deverão ser lidos pelo Usuário. O Usuário será o único responsável por
                    entender os termos e condições de contratação dos produtos e serviços que serão oferecidos/contratados a partir
                    das simulações realizadas no Site. A SOCIEDADE NÃO PODERÁ SER RESPONSABILIZADA POR QUALQUER PERDA OU DANO QUE O
                    USUÁRIO POSSA INCORRER COM ESTAS CONTRATAÇÕES.
                </p>
                <p>
                    <span className="font-weight-bold text-dark">VI – Disposições Gerais</span>
                </p>
                <p className="text-justify">
                    A tolerância ao eventual descumprimento de quaisquer das cláusulas e condições do presente instrumento não
                    constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade das mesmas a
                    qualquer tempo. A presente Política de Privacidade constitui acordo integral entre as partes envolvidas,
                    prevalecendo sobre qualquer outro entendimento firmado anteriormente.
                    O uso deste Site e qualquer litígio decorrente dessa utilização estão sujeitos às leis da República Federativa
                    do Brasil e dos tribunais da Comarca Central da Capital do Estado de São Paulo.
                </p>
            </div>

            <div className="modal-footer d-flex justify-content-start">
                <button type="button" onClick={props.handleToggleModal} className="close-modal-button">FECHAR
                </button>
            </div>

        </ReactModal>
    );
}

export default TermsModal