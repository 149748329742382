import React from 'react';
import "react-step-progress-bar/styles.css";
import "./css/ClientPage.css";
import './css/bootstrap.min.css';
import './css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Header from "./components/Header"
import Footer from "./components/Footer"

class ErrorPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth
        };
    }

    render() {


        const { width } = this.state;
        const isMobile = width < 576;

        return (
            <div>
                <div>
                    <Header/>
                </div>

                <div className="text-center mb-3">
                        <p className="h3" >Algo de errado ocorreu...</p>
                        <p>Por favor, entre em contato com o SAC através do e-mail <span className="text-blue">atendimento@pagdividido.com.br</span></p>
                </div>

                <div style={{width: "100%", height: "1px", "background-color": "#c2c2c2"}}/>

                <div className="mt-3" style={{ "display": `${isMobile ? "none" : "block"}` }}>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default ErrorPage