import React from 'react';
import TextField from "./components/TextField";
import ContractModal from "./components/ContractModal"
import axios from "axios";
import qs from "query-string";
import ErrorMessage from "./components/ErrorMessage";
import CryptoJS from "crypto-js";
import Header from './components/Header';
import { Checkbox } from '@material-ui/core';
import UserProfile from './UserProfile';

class SimuleCustomCliente extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: "",
            loading1: false,
            loading2: false,
            step: 0,
            searched: false,
            success: false,
            cpf: "",
            checkout: {},
            offers: [{ value: "", text: "Oferta", disabled: true }],
            selectedOffer: "",
            uuid: "",
            ownId: "",
            sellerEmail: "",
            modalOpen: true,
            name: "",
            credentials: ""
        };
    }

    componentDidMount() {
        //TODO set name e credentials
        this.setState({
            name: this.props.name,
            credentials: this.props.credentials
        })

        if (!UserProfile.getId()) UserProfile.setId(Date.now())

        if (this.props.match.params.merchantName) {
            axios.get(process.env.REACT_APP_MANAGEMENT_URL + `/partners/search?stub=${this.props.match.params.merchantName}`)
                .then(response => {
                    if (response.data.length === 0) {
                        throw new Error("no return")
                    } else {
                        this.setState({
                            name: response.data.name,
                            credentials: response.data.credentials
                        })
                    }
                })
                .catch(e => {
                    console.log(e)
                    this.props.history.push('/naoencontrada')
                })
        }

        if (typeof this.props.match.params.uuid != 'undefined'
            && qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).orderId) {
            this.setState({
                uuid: this.props.match.params.uuid,
                ownId: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).orderId
            })

            if (qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).seller) {
                this.setState({
                    sellerEmail: atob(qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).seller)
                })
            }
        }
        this.doAnalysis()
    }

    nextStep = () => {
        this.setState(prevState => {
            return { step: prevState.step + 1 }
        }, function () {
            this.setState(this.state);
        });
    };


    verifyCpfAndGetData = async (e) => {
        if (this.state.loading1) return false
        if (this.state.cpf !== "" && this.state.searched === false) {
            try {
                this.setState({ errorMessage: "" });
                this.setState({ loading1: true });
                let response = await axios.get(`${process.env.REACT_APP_CONTRACT_URL}/offer/${this.state.uuid}?document=${this.state.cpf}`, { auth: this.state.credentials });
                let offers = response.data.offers.map(offer => ({ value: offer.uuid, text: offer.offerDescription, disabled: false, numberOfInstallments: offer.numberOfInstallments }));
                offers.sort((a, b) => a.numberOfInstallments - b.numberOfInstallments)
                this.setState({ checkout: response.data.checkout, offers: [...this.state.offers, ...offers] });
                this.setState({ searched: true, loading1: false });
                this.sendAnalysis()
                if (response.data.status == "APPROVED") 
                {
                     this.setState({ success: true });
                     this.nextStep()
                }
                
            } catch (e) {
                console.log(e);
                this.setState({ loading1: false });
                if (e.response && e.response.data && e.response.data.error && e.response.data.error.message === "incorrect document number") {
                    this.setState({ errorMessage: "CPF incorreto" })
                } else {
                    this.setState({ errorMessage: "Erro ao procurar ofertas" })
                }

            }
        } else {
            this.setState({ errorMessage: "Insira seu CPF" })
        }
    };

    checkoutOrder = async () => {
        try {
            this.setState({ errorMessage: "" });
            this.setState({ loading2: true });
            let data = this.state.checkout;
            data.ownId = this.state.ownId;
            data.offerUUID = this.state.selectedOffer;
            data.sellerEmail = this.state.sellerEmail;
            let response = await axios.post(process.env.REACT_APP_CHECKOUT_URL, data, { auth: this.state.credentials });
            if (response.data.orderStatus === "captured") {
                this.setState({ success: true, loading2: false });
            } else if (response.data.orderStatus === "rejected") {
                this.setState({ success: false, loading2: false });
            }

            this.nextStep();
        } catch (e) {
            console.log(e);
            this.setState({ success: false, loading2: false });
            this.nextStep();
        }
    };

    handleCPFChange = (event) => {
        if (event.target.value.length <= 11) {
            this.handleInputChange(event);
        }
    };
    handleToggleModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen })
    };
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };
    encryptData = (data) => {
        let encryptKey = process.env.REACT_APP_ENCRYPT_KEY;

        if (data && data != "" && encryptKey) {
            var encryptedBody = CryptoJS.AES.encrypt(
                data.username + ":" + data.password,
                encryptKey
            );
            var encryptedString = encryptedBody.toString();
            return encryptedString
        } else {
            return undefined
        }
    };

    async doAnalysis() {
        try{
            window.seon.config({
                host: "semear.pagdividido.com.br",
                session_id: UserProfile.getId(),
                audio_fingerprint: true,
                canvas_fingerprint: true,
                webgl_fingerprint: true,
                onSuccess: function (message) {
                  console.log("success", message);
                },
                onError: function (message) {
                  console.log("error", message);
                },
              });
              UserProfile.setFingerprint(await window.seon.getBase64Session())
              console.log("-->analysis", UserProfile.getFingerprint());
        }
        catch(e){
            console.log("-->error")
        }

    }

    async sendAnalysis() {
        try{
        var document = this.state.checkout.customer.taxDocument.find(x=>{
            return x.type == "CPF"
        })
        const res = await axios.get('https://geolocation-db.com/json/')
        var ip = res.data.IPv4
        var phone = this.state.checkout.customer.phone[0]


        var data = {
            email: this.state.checkout.customer.email,
            cpf: document.number,
            session_id: UserProfile.getId(),
            fingerprint: UserProfile.getFingerprint(),
            phone: ''+ phone.countryCode + phone.areaCode + phone.number,
            ip: ip
          }

        let response = await axios.post(process.env.REACT_APP_CROSSSEON_URL + "/analysis", data, { auth: this.state.credentials });
        }catch(e){
            console.log("-->error")
        }

    }

    render() {
        const { width } = this.state;
        const isMobile = width < 576;
        var encryptedCredentials = this.encryptData(this.state.credentials)

        return (
            <div class="container-fluid">
                <Header />
                {this.state.step === 0 && !this.state.searched &&
                    <div className="row justify-content-center ml-3 mr-3">
                        <section className="col col-lg-4 p-5 form-container mt-1">
                            <p style={{ fontSize: '1.2rem' }}>
                                Você tem um crédito pré-aprovado para comprar na {this.state.name}!
                            </p>
                            <p style={{ fontSize: '1.2rem' }}>
                                Escolha seu parcelamento e complete o processo de aprovação para finalizar o seu pedido!
                            </p>
                            <TextField
                                type="text"
                                name="cpf"
                                id="cpfField"
                                label="CPF (Somente Números)"
                                pattern="[0-9]*"
                                value={this.state.cpf}
                                onChange={this.handleCPFChange} />
                            <div className="row term-acceptance">
                                <div className='col-1 p-0'><Checkbox label="Aceito" /></div>
                                <div className='col-11'><span>Aceito compartilhar meus dados com a PagDividido, declarando que li e concordo com os
                                    <a href="https://www.geru.com.br/emprestimos/termos-de-cadastro/" target='_blank'> termos </a> e
                                    <a href="https://www.geru.com.br/emprestimos/politica-de-privacidade/" target='_self'> política de privacidade </a> do empréstimo.
                                </span></div>

                            </div>
              
                            <button
                                onClick={this.verifyCpfAndGetData}
                                disabled={this.state.loading1}
                                className="row align-content-between send-button-client"
                                style={{ width: '100%', maxWidth: '400px' }}>
                                <div style={{ "margin": "auto", "margin-top": "0" }}>
                                    {this.state.loading1 &&
                                        <i
                                            className="fas fa-spinner fa-pulse fa-fw mr-3"
                                            style={{ "fontSize": "30px", "color": "#FFFFFF", "position": "relative", "left": "10px", "marginTop": "13px" }}
                                        />
                                    }
                                    <span>VERIFICAR PARCELAS</span>
                                </div>
                            </button>


                            {this.state.errorMessage &&
                                <ErrorMessage message={this.state.errorMessage} />}
                        </section>
                    </div>
                }
                {this.state.searched && (this.state.step == 0) &&
                    <div className='row justify-content-center'>
                        <div className='col col-lg-4 p-5 mt-1 form-container'>
                            <div className='row'>
                                <div className='col text-center'>
                                    <span className='form-container-title'>
                                        Valor do financiamento:
                                    </span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col text-center'>
                                    <span className='form-container-amount'>
                                        <span style={{ color: "#1BC37F" }}>R$ </span>{(this.state.checkout.amount.total / 100).toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 })}
                                    </span>
                                </div>
                            </div>
                            <div className="w-100 offer-line mt-3 mb-3" />
                            <div >
                                Escolha seu parcelamento e complete o processo de aprovação para finalizar o seu pedido!
                            </div>
                            <TextField
                                select
                                native
                                data={this.state.offers}
                                name="selectedOffer"
                                id="selectedOfferField"
                                label="Selecione a oferta de parcelamento"
                                value={this.state.selectedOffer}
                                onChange={this.handleInputChange} />

                            <button
                                onClick={this.checkoutOrder}
                                disabled={this.state.loading2}
                                className="row align-content-between send-button-client"
                                style={{ width: '100%', maxWidth: '400px' }}>
                                <div style={{ "margin": "auto" }}>
                                    {this.state.loading2 &&
                                        <i
                                            className="fas fa-spinner fa-pulse fa-fw mr-3"
                                            style={{ "font-size": "30px", "color": "#FFFFFF", "position": "relative", "left": "10px", "margin-top": "13px" }}
                                        />
                                    }
                                    <span>FINALIZAR PEDIDO</span>
                                </div>
                            </button>
                        </div>
                    </div>
                }
                {
                //Integração com assinatura de contrato
                /* {this.state.step === 1 && this.state.success &&
                    <div className='row justify-content-center'>
                        <div className='col col-lg-8 p-md-5 p-0 mt-1 contract-container'>

                            <div className="row">
                                <div className="col text-center">
                                    <ContractModal encryptedCredentials={encryptedCredentials} isMobile={isMobile} ownId={this.state.ownId} isOpen={this.state.modalOpen} handleToggleModal={this.handleToggleModal} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center">
                                    <p className="text-footnote">Após a finalização desse processo você receberá um email da {this.state.name} sobre o andamento do seu pedido.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                } */}
                                {this.state.step === 1 && this.state.success &&
                    <div className='row justify-content-center'>
                        <div className='col col-lg-8 p-md-5 p-0 mt-1 contract-container'>
                            <div className="row">
                                <div className="col text-center">
                                    <p className="text-title">Parabéns! Seu processo de pré-aprovação foi finalizado.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center">
                                    <p className="text-footnote">Você receberá uma mensagem via SMS para finalizar o processo no seu telefone celular..</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center">
                                    <p className="text-footnote">Após a finalização desse processo você receberá um email da {this.state.name} sobre o andamento do seu pedido.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.step === 1 && !this.state.success &&
                    <div className="row justify-content-center">
                        <section style={{ fontSize: '1.2rem' }} className="col form-container mt-1">
                            <h4 className="text-justify">
                                Infelizmente neste momento não conseguimos realizar seu pedido de empréstimo em nenhum de nossos parceiros. Nossos parceiros recomendam tentar novamente em 6 meses.
                            </h4>

                            <p className="text-center">
                                <span className="pagdividido-pink text-center">Alguns motivos do porquê não foi possível conceder um empréstimo</span>
                            </p>
                            <ul style={{ paddingLeft: "20px" }} className="text-justify">
                                <li>Lembre-se, para manter uma boa saúde financeira é importante que o valor da parcela comprometa, apenas, até 30% da sua renda mensal.</li>
                                <li>Verifique se não há parcelas em atraso em outras instituições.</li>
                                <li>Confira se o seu CPF está negativado ("Nome Sujo"). Você pode consultar essa informação&nbsp;
                                    <a href="https://www.serasaconsumidor.com.br/consultar-meu-cpf/">AQUI</a>.
                                </li>
                                <li>Como está o seu histórico financeiro? Se você paga as contas sempre em atraso isso pode impactar a chance de você conseguir crédito no mercado financeiro.</li>
                            </ul>
                        </section>
                    </div>
                }
            </div>
        )
    }
}


export default SimuleCustomCliente
