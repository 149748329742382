import React from "react";
import { withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const CssTextField = withStyles({
    root: {
        '.MuiInputBase-input': {
            "font-family": 'Montserrat',
        },
        '& input': {
            "color": "#384268"
        },
        '& .MuiInputBase-root': {
            width: "400px",
            '@media (max-width: 727px)': {
                width: '100%'
            },
        },
        '& .MuiSelect-select': {
            background: "white",
        },
        '& label': {
            background: "white",
            paddingLeft: "7px",
            paddingRight: "5px",
        },
        '& label.Mui-focused': {
            color: '#10d37c',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#c4c4c4',
                borderWidth: "2px",
                borderRadius: "10px",
                width: "400px",
                '@media (max-width: 727px)': {
                    width: '100%'
                }
            },
            '&:hover fieldset': {
                borderColor: '#c4c4c4',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#10d37c',
                borderWidth: "2px",
                borderRadius: "10px",
                notched: true
            },
        },
    },
})(TextField);

class Input extends React.Component {

    render() {
        const { type, name, id, label, required, disabled, value, onChange, onBlur, select, native, data } = this.props;

        return (
            <CssTextField
                select={select}
                SelectProps={{native: native}}
                type={type}
                name={name}
                id={id}
                placeholder={label}
                label={label}
                required={required}
                disabled={disabled}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                variant="outlined"
                margin="normal"
                fullWidth>

                {data &&
                    data.map(option => <option key={data.indexOf(option)} value={option.value} disabled={option.disabled}>{option.text}</option>)
                }

            </CssTextField>


        )
    }
}

export default (Input);