export const ufOptions = [
    {value: "", text: "--", disabled: true},
    {value:"AC", text: "AC", disabled: false},
    {value:"AL", text: "AL", disabled: false},
    {value:"AP", text: "AP", disabled: false},
    {value:"AM", text: "AM", disabled: false},
    {value:"BA", text: "BA", disabled: false},
    {value:"CE", text: "CE", disabled: false},
    {value:"DF", text: "DF", disabled: false},
    {value:"GO", text: "GO", disabled: false},
    {value:"ES", text: "ES", disabled: false},
    {value:"MA", text: "MA", disabled: false},
    {value:"MT", text: "MT", disabled: false},
    {value:"MS", text: "MS", disabled: false},
    {value:"MG", text: "MG", disabled: false},
    {value:"PA", text: "PA", disabled: false},
    {value:"PB", text: "PB", disabled: false},
    {value:"PR", text: "PR", disabled: false},
    {value:"PE", text: "PE", disabled: false},
    {value:"PI", text: "PI", disabled: false},
    {value:"RJ", text: "RJ", disabled: false},
    {value:"RN", text: "RN", disabled: false},
    {value:"RS", text: "RS", disabled: false},
    {value:"RO", text: "RO", disabled: false},
    {value:"RR", text: "RR", disabled: false},
    {value:"SP", text: "SP", disabled: false},
    {value:"SC", text: "SC", disabled: false},
    {value:"SE", text: "SE", disabled: false},
    {value:"TO", text: "TO", disabled: false}
];

export const dispatcherOptions = [
    {value: "", text: "Selecione", disabled: true},
    {value: "SSP", text: "Secretaria de Segurança Pública", disabled: false},
    {value: "DPF", text: "Departamento da Polícia Federal", disabled: false},
    {value: "MAE", text: "Ministério da Aeronáutica", disabled: false},
    {value: "MEX", text: "Ministério do Exército", disabled: false},
    {value: "MMA", text: "Ministério da Marinha", disabled: false},
    {value: "OUT", text: "Outro", disabled: false}
];

export const genderOptions = [
    {value: "", text: "--", disabled: true},
    {value: "F", text: "Feminino", disabled: false},
    {value: "M", text: "Masculino", disabled: false},
    {value: "O", text: "Outro", disabled: false}
];

export const maritalOptions = [
    {value: "", text: "Selecione", disabled: true},
    {value: "Casado", text: "Casado", disabled: false},
    {value: "Solteiro", text: "Solteiro", disabled: false},
    {value: "Divorciado", text: "Divorciado", disabled: false},
    {value: "Viuvo", text: "Viúvo", disabled: false},
    {value: "Uniao Estavel", text: "União Estável", disabled: false}
];

export const occupationOptions = [
    {value: "", text: "Selecione", disabled: true},
    {value: "Empregado Setor Privado", text: "Empregado Setor Privado", disabled: false},
    {value: "Empregado Setor Publico", text: "Empregado Setor Público", disabled: false},
    {value: "Profissional Liberal", text: "Profissional Liberal", disabled: false},
    {value: "Empresario", text: "Empresário", disabled: false},
    {value: "Aposentado ou Pensionista", text: "Aposentado ou Pensionista", disabled: false},
    {value: "Autonomo", text: "Autônomo", disabled: false},
    {value: "Outros", text: "Outros", disabled: false}
];

export const scholarityOptions = [
    {value: "", text: "Selecione", disabled: true},
    {value: "Fundamental", text: "1º Grau Completo (Fundamental)", disabled: false},
    {value: "Ensino Médio", text: "2º Grau Completo (Ensino Médio)", disabled: false},
    {value: "Superior Incompleto", text: "Superior Incompleto", disabled: false},
    {value: "Superior Completo", text: "Superior Completo", disabled: false},
    {value: "Pós-Graduado", text: "Pós-Graduado", disabled: false}
];

export const stateOptions = [
    {value: "", text: "--", disabled: true},
    {value: "AC", text: "Acre", disabled: false},
    {value: "AL", text: "Alagoas", disabled: false},
    {value: "AP", text: "Amapá", disabled: false},
    {value: "AM", text: "Amazonas", disabled: false},
    {value: "BA", text: "Bahia", disabled: false},
    {value: "CE", text: "Ceará", disabled: false},
    {value: "DF", text: "Distrito Federal", disabled: false},
    {value: "GO", text: "Goiás", disabled: false},
    {value: "ES", text: "Espírito Santo", disabled: false},
    {value: "MA", text: "Maranhão", disabled: false},
    {value: "MT", text: "Mato Grosso", disabled: false},
    {value: "MS", text: "Mato Grosso do Sul", disabled: false},
    {value: "MG", text: "Minas Gerais", disabled: false},
    {value: "PA", text: "Pará", disabled: false},
    {value: "PB", text: "Paraiba", disabled: false},
    {value: "PR", text: "Paraná", disabled: false},
    {value: "PE", text: "Pernambuco", disabled: false},
    {value: "PI", text: "Piauí­", disabled: false},
    {value: "RJ", text: "Rio de Janeiro", disabled: false},
    {value: "RN", text: "Rio Grande do Norte", disabled: false},
    {value: "RS", text: "Rio Grande do Sul", disabled: false},
    {value: "RO", text: "Rondônia", disabled: false},
    {value: "RR", text: "Roraima", disabled: false},
    {value: "SP", text: "São Paulo", disabled: false},
    {value: "SC", text: "Santa Catarina", disabled: false},
    {value: "SE", text: "Sergipe", disabled: false},
    {value: "TO", text: "Tocantins", disabled: false}
];

export const residentOptions = [
    {value: "", text: "Selecione", disabled: true},
    {value: "Propria Quitada", text: "Própria Quitada", disabled: false},
    {value: "Alugada", text: "Alugada", disabled: false},
    {value: "Propria Financiada", text: "Própria Financiada", disabled: false},
    {value: "De Familiares ou Conjuge", text: "De Familiares ou Cônjuge", disabled: false}
];

export const bankOptions = [
    {value: "", text: "--", disabled: true},
    {value: "001", text: "Banco do Brasil", disabled: false},
    {value: "033", text: "Santander", disabled: false},
    {value: "104", text: "Caixa Econômica Federal", disabled: false},
    {value: "237", text: "Bradesco", disabled: false},
    {value: "341", text: "Itaú", disabled: false},
    {value: "004", text: "Banco do Nordeste do Brasil", disabled: false},
    {value: "021", text: "BANESTES", disabled: false},
    {value: "037", text: "Banco do Estado do Pará", disabled: false},
    {value: "041", text: "Banrisul", disabled: false},
    {value: "070", text: "BRB - Banco de Brasilia", disabled: false},
    {value: "077", text: "Banco Inter", disabled: false},
    {value: "121", text: "AGIBANK", disabled: false},
    {value: "212", text: "Banco Original", disabled: false},
    {value: "260", text: "Nubank", disabled: false},
    {value: "637", text: "Banco Sofisa", disabled: false},
    {value: "655", text: "Banco Votorantim", disabled: false},
    {value: "748", text: "Sicredi", disabled: false},
    {value: "000", text: "Outro banco", disabled: false}
];

export const accountTypeOptions = [
    {value: "", text: "--", disabled: true},
    {value: "001", text: "001 Conta Corrente Pessoa Física", disabled: false},
    {value: "013", text: "013 Poupança", disabled: false}
];