import React from 'react';
import Header from "./components/Header"
import Footer from "./components/Footer"
import Simulation from './components/SimulationZarpo';

const merchantLogoUrl = "https://pigpeg-dev.s3.amazonaws.com/logo.zarpo.png"

class Simule extends React.Component {
    render() {
        return (
            <div>
                <Header merchantLogoUrl={merchantLogoUrl}/>
                <Simulation />
                <Footer />
            </div>
        )
    }
}


export default Simule