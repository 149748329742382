import React from 'react'

function ErrorMessage(props) {
    return (
        <div className="mt-3" style={{color: "red", fontWeight: "300"}}>
            <i className="fas fa-exclamation"/> {props.message}
        </div>
    );
}

export default ErrorMessage