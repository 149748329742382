import React from 'react';
import axios from "axios";
import qs from "query-string";
import step1Filled from "./images/step1-filled.png"
import step2Filled from "./images/step2-filled.png"
import step1UnFilled from "./images/step1-unfilled.png"
import step2UnFilled from "./images/step2-unfilled.png"
import spinner from "./images/spinner.svg"
import {ProgressBar, Step} from "react-step-progress-bar";
import LoadingScreen from "react-loading-screen";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ErrorMessage from "./components/ErrorMessage";
import TermsModal from "./components/TermsModal";
import OfferCard from "./components/OfferCard";
import { withStyles } from "@material-ui/core";
import "react-step-progress-bar/styles.css";
import "./css/ClientPage.css";
import './css/bootstrap.min.css';
import './css/spinner.css'
import DateField from "./components/DateMaskField";
import PhoneField from "./components/PhoneMaskField";
import CellphoneField from "./components/CellphoneMaskField";
import CepField from "./components/ZipCodeField";
import TextField from "./components/TextField";
import * as options from "./utils/optionsConstants"
import Header from "./components/Header"
import Footer from "./components/Footer"
import ReactGA from 'react-ga';
import {firebaseApp} from "./base";
import {auth} from "firebase";

ReactGA.initialize('UA-145817195-2');

const MaterialStyles = withStyles({
    '@global': {
        '.MuiFormControlLabel-label': {
            "font-family": 'Montserrat'
        }
    },
})(() => null);

let inputError = "";

class ClientPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            height: window.innerHeight,
            width: window.innerWidth,
            step: 0,
            loading: false,
            approved: false,
            captureApproved: false,
            denied: false,
            stringAmount: "",
            noPhone: false,
            modalOpen: false,
            inputError: "",
            preCpf: false,
            captchaOk: false,
            smsReload: false,

            name: "",
            firstname: "",
            cpf: "",
            birthdate: "",
            optin: false,
            rg: "",
            uf: "",
            gender: "",
            phone: "",
            cellphone: "",
            motherName: "",

            zipCode: "",
            address:"",
            number: "",
            complement: "",
            neighborhood: "",
            city: "",
            state: "",
            birthCity: "",
            birthState: "",

            loanPeriod: 0,
            items: [],
            amount: Object,
            taxDocument: {
                type: "CPF",
                number: ""
            },
            email: "",
            merchantName: "",
            merchantId: "",
            utm_source: "",
            logoUrl: "",

            clientId: "",
            proposal_id: "",
            selectedPartner: "",
            checkoutResponse: {},
            smsVerificationCode: "",
            firebase_uid: "",

            crossgeruAuthData: {
                username: process.env.REACT_APP_CROSSGERU_USERNAME,
                password: process.env.REACT_APP_CROSSGERU_PASSWORD
            },
            crossrebelAuthData: {
                username: process.env.REACT_APP_CROSSREBEL_USERNAME,
                password: process.env.REACT_APP_CROSSREBEL_PASSWORD
            },
            contractAuthData: {
                username: process.env.REACT_APP_CONTRACT_USERNAME,
                password: process.env.REACT_APP_CONTRACT_PASSWORD
            },
            communicationAuthData: {
                username: process.env.REACT_APP_COMMUNICATION_USERNAME,
                password: process.env.REACT_APP_COMMUNICATION_PASSWORD
            },
            checkoutAuthData: {
                username: process.env.REACT_APP_CHECKOUT_USERNAME,
                password: process.env.REACT_APP_CHECKOUT_PASSWORD
            },
        };

        this.state.step === 0 && ReactGA.ga('send', 'event', 'Enroll', 'onLoad Page', 'Enroll Inicio');

        this.validators = {
            name: function () {
                if (!this.value.match(/([a-zA-Z]+)( +[a-zA-Z]+)+/))
                    return "Por favor insira seu nome completo";
                else
                    return ""
            },

            email: function () {
                if (!this.value.match(
                    // eslint-disable-next-line no-useless-escape
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
                    return "Por favor insira um e-mail válido";
                else
                    return ""
            },

            cellphone: function () {
                if ((this.value.match(/[(][1-9]{2}[)] 9[1-9]\d{3}-\d{4}/) || this.value.match(/[1-9]{2} 9[1-9]\d{7}/)))
                    return "";
                else
                    return "Telefone celular inválido";
            },

            optin: function () {
                if (this.state.optin === true) {
                    return "";
                } else {
                    return "Para continuar é necessário concordar com os termos de serviço"
                }
            },

            smsVerificationCode: function () {
                if (this.value.match(/\d+/)) {
                    return "";
                } else {
                    return "Insira o código de verificação"
                }
            },

            cpf: function() {
                if (!this.value.match(/^\d{11}$/))
                    return "CPF inválido";
                else
                    return ""
            },

            motherName: function() {
                if (!this.value.match(/([a-zA-Z]+)( +[a-zA-Z]+)+/))
                    return "Por favor insira o nome completo de sua mãe";
                else
                    return ""
            },

            birthdate: function() {
                if (!this.value.match(/(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/(19\d{2}|2\d{3})/))
                    return "Data de nascimento inválida";
                else if (parseInt(this.value.slice(this.value.length-4, this.value.length)) > new Date().getFullYear())
                    return "Data de nascimento inválida";
                else
                    return ""
            },

            rg: function() {
                if (!this.value.match(/^(\d{1,2})(\d{7})$/))
                    return "RG inválido";
                else
                    return ""
            },

            uf: function() {
                if (!this.value)
                    return "Escolha uma UF";
                else
                    return ""
            },

            gender: function() {
                if (!this.value)
                    return "Escolha um sexo";
                else
                    return ""
            },

            phone: function() {
                if (!this.value.match(/[(][1-9]{2}[)] [2-8]\d{3}-\d{4}/))
                    return "Telefone fixo inválido";
                else
                    return ""
            },

            zipCode: function() {
                if (!this.value.match(/\d{5}-\d{3}/))
                    return "CEP inválido";
                else
                    return ""
            },

            address: function() {
                if (!this.value)
                    return "Endereço inválido";
                else
                    return ""
            },

            number: function() {
                if (!this.value)
                    return "Número inválido";
                else
                    return ""
            },

            neighborhood: function() {
                if (!this.value)
                    return "Bairro inválido";
                else
                    return ""
            },

            city: function() {
                if (!this.value)
                    return "Cidade inválida";
                else
                    return ""
            },

            state: function() {
                if (!this.value)
                    return "Escolha um estado";
                else
                    return ""
            },
        };
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        clearInterval(this.interval);
        clearTimeout(this.timeout);
        firebaseApp.auth().signOut();
    };

    reloadPage = () => {
        window.location.reload();
    };

    setupCaptcha = () => {
        window.recaptcha = new auth.RecaptchaVerifier(this.recaptcha, {
            "size": "normal",
            "callback": () => {
                // TODO Adicionar rate limit?
                var phoneNumber = "+55" + this.state.cellphone.replace(/[() \-]/g, "");
                firebaseApp.auth().signInWithPhoneNumber(phoneNumber, window.recaptcha)
                    .then(confirmationResult => {
                        this.setState({captchaOk: true});
                        window.confirmationResult = confirmationResult;
                    })
                    .catch(error => {
                        this.recaptcha.hidden = true;
                        //window.recaptcha.reset(window.recaptchaWidgetId);
                        console.log(error);
                        inputError = "Erro ao enviar SMS de verificação. Entre em contato com o nosso suporte em atendimento@pagdividido.com.br";
                        this.setState({inputError: inputError});
                    });
            },
            'expired-callback': function() {
                console.log("captcha expired");
                window.recaptcha.reset(window.recaptchaWidgetId);
            }
        });

        window.recaptcha.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
        });

        window.addEventListener('resize', this.handleWindowSizeChange);
    };

    resendCaptcha = () => {
        if (window.invisibleRecaptcha) {
            window.invisibleRecaptcha.reset(window.invisibleRecaptchaWidgetId);
            this.invisibleRecaptcha.remove();
        }

        window.invisibleRecaptcha = new auth.RecaptchaVerifier(this.invisibleRecaptcha, {
            "size": "invisible"
        });

        window.invisibleRecaptcha.render()
            .then((widgetId) => {
                window.invisibleRecaptchaWidgetId = widgetId;
                var phoneNumber = "+55" + this.state.cellphone.replace(/[() \-]/g, "");
                firebaseApp.auth().signInWithPhoneNumber(phoneNumber, window.invisibleRecaptcha)
                    .then(confirmationResult => {
                        window.confirmationResult = confirmationResult;
                        console.log("resent sms");
                        this.setState({smsReload: true})
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                this.setState({smsError: true});
            })
    };

    componentDidMount() {
        this.setupCaptcha();

        const orderId = this.props.match.params.orderId;
        const name = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).name;
        const firstname = (this.state.name !== "") ? this.state.name.split(" ")[0] : this.state.name;
        const cpf = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).cpf; // 11111111111
        const birthdate = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).birthdate; // 25/03/1996
        const email = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).email;
        const merchantId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).merchantId;
        const amount = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).amount;
        const cellphone = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).cellPhone;
        let utm_source =
            qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).utm_source;
        let loanPeriod = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).loanPeriod;
        if (typeof loanPeriod === "undefined") {
            loanPeriod = 0
        }


        if (typeof orderId !== "undefined") {
            axios
                .get(process.env.REACT_APP_CONTRACT_URL + "get/" + orderId,{auth: this.state.contractAuthData})
                .then(response => {
                    const data = response.data;

                    if (typeof data.merchant.id !== "undefined") {
                        utm_source = data.merchant.id;
                    }

                    this.setState(data.client, function () {
                        if (typeof data.items !== "undefined") {
                            this.setState({items: data.items})
                        }
                        if (typeof data.amount.total !== "undefined") {
                            this.setState({amount: data.amount, stringAmount: data.amount.total.toString()})
                        }
                        if (typeof data.taxDocument.number !== "undefined") {
                            this.setState({cpf: data.taxDocument.number, taxDocument: data.taxDocument, preCpf: true})
                        }
                        this.setState({
                                email: data.email,
                                merchantId: data.merchant.id,
                                utm_source: utm_source,
                                merchantName: data.merchant.name},
                            this.prepareAndCreateClient);
                    });
                })
                .catch(error => console.log(error));

        } else {

            //Grava estado caso dados venham da Zarpo
            if (merchantId === "MCXOVI-6714") {
                this.setState({
                    name: name,
                    firstname: firstname,
                    cpf: cpf,
                    taxDocument: {
                        type: "CPF",
                        number: cpf
                    },
                    preCpf: typeof cpf !== "undefined",
                    email: email,
                    merchantId: merchantId,
                    utm_source: merchantId,
                    amount: {
                        total: parseInt(amount)
                    },
                    stringAmount: amount,
                    loanPeriod: 12,
                    cellphone: cellphone
                });
            }

            if (name === "" || birthdate === "" || email === "" || merchantId === "" || amount === "" ||
                typeof name === "undefined" || typeof birthdate === "undefined" ||
                typeof email === "undefined" || typeof merchantId === "undefined" || typeof amount === "undefined") {
                console.log("Query params error");
                    //Grava os dados se o cliente vier do simulator
                if (merchantId !== "") {
                    this.setState({
                        merchantId: merchantId,
                        utm_source: utm_source,
                        amount: {
                            total: parseInt(amount)
                        },
                        stringAmount: amount,
                        loanPeriod: parseInt(loanPeriod),
                    })
                }


            } else {
                this.setState({
                    name: name,
                    firstname: firstname,
                    birthdate: birthdate,
                    email: email,
                    merchantId: merchantId,
                    utm_source: utm_source,
                    amount: {
                        total: parseInt(amount)
                    },
                    stringAmount: amount,
                    loanPeriod: parseInt(loanPeriod),
                    });
            }

            if (cpf) {
                this.setState({
                    cpf: cpf,
                    taxDocument: {
                        type: "CPF",
                        number: cpf
                    },
                    preCpf: typeof cpf !== "undefined",
                }, this.prepareAndCreateClient)
            }
        }
    };

    // Flow functions --------------------------------------------------------------------------------------------------
    mergeDiretrixData = (diretrix) => {
        if (diretrix.annotaded === true) {
            this.annotadedRef.style.display = "none"
        }
        if (diretrix.motherName !== "") {
            this.motherNameRef.style.display = "none"
        }
        if (diretrix.phone !== "") {
            this.phoneRef.style.display = "none"
        }
        if (diretrix.rg !== "") {
            this.rgRef.style.display = "none"
        }
        if (diretrix.salary !== "") {
            this.salaryRef.style.display = "none"
        }

        this.setState({
            name: this.state.name ? this.state.name: diretrix.name,
            birthdate: this.state.birthdate ? this.state.birthdate: diretrix.birthdate,
            email: this.state.email ? this.state.email: diretrix.email,
            cellphone: this.state.cellphone ? this.state.cellphone: diretrix.cellphone,
            rg: diretrix.rg,
            uf: diretrix.uf,
            emissionDate: diretrix.emissionDate,
            dispatcherOrgan: diretrix.dispatcherOrgan,
            gender: diretrix.gender,
            maritalState: diretrix.maritalState,
            phone: diretrix.phone,

            motherName: diretrix.motherName,
            politicallyExposedPerson: diretrix.politicallyExposedPerson,
            annotaded: diretrix.annotaded,

            salary: diretrix.salary !== "00"? diretrix.salary: "",
            occupation: diretrix.occupation,
            scholarity: diretrix.scholarity,
            employment: diretrix.employment,
            jobTitle: diretrix.jobTitle,
            company: diretrix.company,
            risk: diretrix.risk,

            zipCode: diretrix.zipCode,
            address: diretrix.address,
            number: diretrix.number,
            complement: diretrix.complement !== "Sem complemento"? diretrix.complement: "",
            neighborhood: diretrix.neighborhood,
            city: diretrix.city,
            state: diretrix.state,
            residentType: diretrix.residentType
        })
    };

    dataToCheckout = () => {
        let phone = this.state.cellphone
        let areaCode = parseInt(phone.match(/\d{2}/)[0])
        let number = parseInt(phone.replaceAll(/[^0-9]/g, '').match(/9[1-9]\d{7}/)[0])
        let data = {
            name: this.state.name,
            email: this.state.email,
            dateOfBirth: `${this.state.birthdate.substring(6,10)}-${this.state.birthdate.substring(3,5)}-${this.state.birthdate.substring(0,2)}`,
            cpf: this.state.cpf,
            phone: {
                countryCode: 55,
                areaCode: areaCode,
                number: number
            },
            amount: this.state.amount,
            motherName: this.state.motherName,
            gender: this.state.gender,
        }
        return data
    }

    usefulData = () => {
        return {
            clientId: this.state.clientId,
            smsVerificationCode: this.state.smsVerificationCode,
            firebase_uid: this.state.firebase_uid,

            name: this.state.name,
            firstname: (this.state.name !== "") ? this.state.name.split(" ")[0] : this.state.name,
            cpf: this.state.cpf || this.state.taxDocument.cpf,
            optin: this.state.optin,
            birthdate: this.state.birthdate,
            rg: this.state.rg,
            gender: this.state.gender,
            phone: this.state.noPhone ? "(21) 2134-5678" : this.state.phone,
            cellphone: this.state.cellphone,
            motherName: this.state.motherName,

            zipCode: this.state.zipCode,
            address: this.state.address,
            number: this.state.number,
            complement: this.state.complement ? this.state.complement: "Sem complemento",
            neighborhood: this.state.neighborhood,
            city: this.state.city,
            state: this.state.state,

            loanPeriod: this.state.loanPeriod,
            items: this.state.items,
            amount: this.state.amount,
            taxDocument: this.state.taxDocument,
            email: this.state.email,
            merchantName: this.state.merchantName,
            utm_source: this.state.utm_source ? this.state.utm_source: "got657kas",
        }
    };

    verifySMS = () => {
        this.validateInputs();

        if (inputError === "") {
            this.loader.style.display = "block";

            if (!window.confirmationResult) {
                return
            }

            window.confirmationResult.confirm(this.state.smsVerificationCode).then(result => {
                // User signed in successfully.
                this.nextStep();
                inputError = "";
                this.setState({inputError: "", firebase_uid: result.user.uid});
                this.loader.style.display = "none";
            }).catch(error => {
                inputError = "Código de verificação incorreto";
                this.setState({inputError: inputError});
                this.loader.style.display = "none";
            });
        }
    };

    prepareAndCreateClient = () => {
        const usefulData = this.usefulData();

        // Prepare client on GERU
        if (this.state.taxDocument.number !== "" && this.state.birthdate !== "") {
            axios
                .post(process.env.REACT_APP_CROSSGERU_URL + "prepare",
                    {"cpf": this.state.taxDocument.number, "birthdate": this.state.birthdate},
                    {auth: this.state.crossgeruAuthData})
                .then(() => {console.log("Prepare on GERU success")})
                .catch(error => {
                    console.log("Prepare on GERU failed");
                    console.log(error)});
        }

        // Create client with basic info and set clientId
        axios
            .post(process.env.REACT_APP_CONTRACT_URL + "create", usefulData,
                {auth: this.state.contractAuthData,
                    headers: {merchantId: this.state.merchantId}
                })
            .then(response => {
                if (response.data.deceased === true) {
                    console.log("CPF of deceased");
                    this.setState({
                        loading: false,
                        approved: false,
                        denied: true,
                    });
                    return
                }

                this.mergeDiretrixData(response.data.diretrixData);

                if (typeof response.data.cpf === "undefined") {
                    console.log("Client created with success. ClientId: " + response.data.clientId);
                } else {
                    console.log("Client found with success. ClientId: " + response.data.clientId + " and CPF: " + response.data.cpf)
                }

                this.setState({
                    clientId: response.data.clientId,
                });

                if (typeof response.data.cpf !== "undefined") {
                    this.setState({
                        cpf: response.data.cpf,
                        taxDocument: {
                            type: "CPF",
                            number: response.data.cpf
                        },
                        preCpf: true
                    })
                }
            })
            .catch(error => {
                console.log("Client create failed");
                console.log(error)});

        // Get merchant info
        axios
            .get(process.env.REACT_APP_COMMUNICATION_URL + "merchants/" + this.state.merchantId,
                {auth: this.state.communicationAuthData})
            .then(response => {
                if (this.state.merchantName === "" || typeof this.state.merchantName === "undefined") {
                    this.setState({merchantName: response.data.name})
                }

                this.setState({
                    logoUrl: response.data.logoUrl,
                })
            })
            .catch(error => {
                console.log("Merchant info get failed");
                console.log(error)});
    };

    updateClient = () => {
        console.log("UpdateClient()");

        const usefulData = this.usefulData();
        axios
            .post(process.env.REACT_APP_CONTRACT_URL + "create", usefulData,
                {auth: this.state.contractAuthData,
                    headers: {merchantId: this.state.merchantId}
                })
            .then(response => {
                if (this.state.clientId !== response.data.clientId){
                    console.log("ClientId updated to " + response.data.clientId)
                }

                this.setState({
                    clientId: response.data.clientId,
                });

                console.log("Client updated with success")
            })
            .catch(error => {
                console.log("Client update fail");
                console.log(error)
            })
    };

    sendForm = (e) => {
        console.log("SendForm()");
        e.preventDefault();
        this.validateInputs();

        if (inputError === "") {
            console.log("No input errors");

            this.setState({loading: true});
            const checkout = this.dataToCheckout();

            axios
                .post(process.env.REACT_APP_CHECKOUT_URL + "/prepare", checkout,
                    {auth: this.state.checkoutAuthData})
                .then(response => {
                    if (response.data.message === "Sem ofertas") {
                        this.setState({
                            loading: false,
                            approved: false,
                            denied: true,
                        });
                    } else {
                        this.setState({
                            loading: false,
                            approved: true,
                            denied: false,
                            checkoutResponse: response.data
                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.setState({
                        loading: false,
                        approved: false,
                        denied: true,
                    });
                });
        }
    };

    updateClientContextToRecovery = () => {
        const proposalWithContext = {
            "notificationContext": "RECOVERY_PARTNER"
        };

        axios
            .post(process.env.REACT_APP_CONTRACT_URL + "update/" + this.state.clientId + "/" + this.state.proposal_id, proposalWithContext,
                {auth: this.state.contractAuthData})
            .catch(error => {
                console.log(error)
            })
    };

    confirmContractAndRedirect = (event) => {
        this.setState({
            loading: false
        });
        ReactGA.ga('send', 'event', 'Partner', 'onClick Partner', 'Partner Select');

        //var newTab = window.open("", "_blank");
        //newTab.location.href = selectedOffer.redirectUrl;

        let selectedOffer = this.state.checkoutResponse.offers[event.currentTarget.name].uuid;

        let phone = this.state.cellphone
        let areaCode = parseInt(phone.match(/\d{2}/)[0])
        let number = parseInt(phone.replaceAll(/[^0-9]/g, '').match(/9[1-9]\d{7}/)[0])

        const captureRequest = {
            ownId: (+ new Date()).toString(), // epoch timestamp
            offerUUID: selectedOffer,
            customer: {
                fullName: this.state.name,
                email: this.state.email,
                gender: this.state.gender,
                birthDate: `${this.state.birthdate.substring(6,10)}-${this.state.birthdate.substring(3,5)}-${this.state.birthdate.substring(0,2)}`,
                birthCity: this.state.birthCity,
                birthState: this.state.birthState,
                taxDocument: [
                    {
                        type: "CPF",
                        number: this.state.cpf
                    },
                    {
                        type: "RG",
                        number: this.state.rg
                    }
                ],
                phone: [
                    {
                        countryCode: 55,
                        areaCode: areaCode,
                        number: number
                    }
                ],
                billingAddresses: [
                    {
                        street: this.state.address,
                        streetNumber: this.state.number,
                        city: this.state.city,
                        state: this.state.state,
                        country: "BRA",
                        zipCode: this.state.zipCode
                    }
                ],
                shippingAddress: {
                    street: this.state.address,
                    streetNumber: this.state.number,
                    city: this.state.city,
                    state: this.state.state,
                    country: "BRA",
                    zipCode: this.state.zipCode
                }
            },
            items: [
                {
                    product: "Simule",
                    detail: "Simule",
                    quantity: 1,
                    price: this.state.amount.total
                }
            ],
            amount: this.state.amount,
            merchant: {
                confirmationUrl: "",
                cancelUrl: "",
                userConfirmationAction: "POST",
                name: "Simule"
            }
        }
        console.log(captureRequest)

        axios
            .post(process.env.REACT_APP_CHECKOUT_URL, captureRequest,
                {auth: this.state.checkoutAuthData})
            .then(response => {
                console.log(response)
                this.setState({
                    loading: false,
                    approved: true,
                    captureApproved: true,
                    denied: false,
                });
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    loading: false,
                    approved: false,
                    denied: true,
                });
            });
    };

    // Front functions -------------------------------------------------------------------------------------------------

    handleWindowSizeChange = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    handleToggleModal = () => {
        this.setState({modalOpen: !this.state.modalOpen})
    };

    maskPrice = (price) => {
        if (typeof price === "undefined") {
            return ""
        }

        let masked;

        masked = price;
        if (masked.length > 5) {
            masked = masked.slice(0, masked.length-5) + "." + masked.slice(masked.length-5, masked.length)
        }

        masked = "R$ " + masked.slice(0, masked.length-2) + "," + masked.slice(masked.length-2, masked.length);

        return masked
    };

    maskAmount = () => {
        if (this.state.stringAmount === "") {
            return ""
        }

        return this.maskPrice(this.state.stringAmount)
    };

    nextStep = (e) => {
        if (typeof e !== "undefined") {
            e.preventDefault();
        }

        this.validateInputs();

        if (inputError === "") {
            // Updates client with new info
            window.scrollTo(0, 0);

            let currentStep = this.state.step;

            this.setState(prevState => {
                return {step: prevState.step + 1}
            }, function () {
                this.setState(this.state);
            });
        }
    };

    previousStep = (e) => {
        if (typeof e !== "undefined") {
            e.preventDefault();
        }

        this.setState(prevState => {
            return {step: prevState.step - 1}
        }, function () {
            this.setState(this.state);
        })
    };

    validateInputs = () => {
        const filter = [
            ["name", "email", "cellphone", "optin"],
            ["smsVerificationCode"],
            ["cpf", "motherName", "birthdate", "rg", "uf", "gender"],
            ["zipCode", "address", "number", "neighborhood", "city", "state", "residentType"],
        ];

        let fieldsToValidate = Object.entries(this.usefulData()).filter(field => {
            return filter[this.state.step].includes(field[0])
        });

        for (let [key, value] of fieldsToValidate) {
            if (typeof this.validators[key] === 'function'){
                let error = this.validators[key].call({value: value, state: this.state});
                if (error !== "") {
                    this.setState({inputError: error});
                    inputError = error;
                    break;
                } else {
                    this.setState({inputError: ""});
                    inputError = ""
                }
            }
        }
    };

    handleCepInput = (event) => {
        this.handleInputChange(event);

        axios
            .get("https://viacep.com.br/ws/" + event.target.value + "/json/")
            .then(response => {
                if (response.data.erro !== true) {
                    this.setState({
                        address: response.data.logradouro,
                        neighborhood: response.data.bairro,
                        city: response.data.localidade,
                        state: response.data.uf
                    })
                }
            })
            .catch(error => {console.log(error)})
    };

    handleCpfInput = (event) => {
        this.handleInputChange(event);
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (this.state.name) {
            this.setState({firstname: this.state.name.split(" ")[0]});
        }

        if (name === "cpf") {
            this.setState({[name]: value, taxDocument: {type: "CPF", number: value}})
        } else {
            this.setState({
                [name]: value
            });
        }
    };

    render() {
        const { width } = this.state;
        const isMobile = width < 576;
        const calcStep = this.state.step === 3? 100: 50

        return (
            <LoadingScreen
                loading={this.state.loading}
                bgColor="rgba(255,255,255,1)"
                spinnerColor="#10d37c"
                textColor="#676767"
                logoSrc="https://moneri.s3-sa-east-1.amazonaws.com/Pagdividido/Imagens/Logo-PagDividido-NovaCor.png"
                text="Estamos procurando a melhor oferta para você">

                <TermsModal isMobile={isMobile} isOpen={this.state.modalOpen} handleToggleModal={this.handleToggleModal}/>

                <div>
                    <Header/>

                    <div className="wrapper">
                        {(this.state.name !== "") ? (
                        <div className="row justify-content-center mb-3">
                            <div className="col welcome-container">
                                <div className="decision-status"
                                     style={{ "display": `${((this.state.approved === true && this.state.denied === false)) ? "block" : "none"}` }}>
                                    <span className="pagdividido-blue">PARABÉNS!</span>
                                </div>

                                <div className="decision-status"
                                     style={{ "display": `${((this.state.approved === false && this.state.denied === true)) ? "block" : "none"}` }}>
                                    <span className="pagdividido-blue mt-2 ml-2">QUE PENA...</span>
                                </div>

                                <div className="text-center"
                                     style={{ "display": `${((this.state.approved === true || this.state.denied === true)) ? "none" : "block"}` }}>

                                <span className="pagdividido-blue"
                                      style={{ "fontWeight": "600" }}>Cliente PagDividido: </span><br />
                                    <span className="pagdividido-pink">{this.state.name}</span> <br />
                                        <span className="pagdividido-blue">
                                    {this.state.merchantId === "got657kas"
                                        ? <span>Valor do crédito desejado:</span>
                                        : <span> Valor desejado para comprar no site
                                            <span className="pagdividido-pink">&nbsp;{this.state.merchantName}</span>:&nbsp;
                                        </span>
                                    }
                                        <br/><span className="pagdividido-pink">{this.maskAmount()}</span>
                                        </span>
                                </div>
                            </div>
                        </div>
                        ) : true }


                        <div className="row justify-content-center mb-5 mt-4"
                             style={{
                                 "display": `${((this.state.approved === false && this.state.denied === false && this.state.step >= 2)) ? isMobile ? "none" : "flex" : "none"}`
                             }}>
                            <div
                                style={{ "width": "400px" }}>
                                <ProgressBar percent={calcStep <= 0 ? 0 : (calcStep >= 100 ? 100 : calcStep)} height={1} unfilledBackground="#7d7d7d"
                                             filledBackground="#10d37c">
                                    <Step>
                                        {() => (
                                            <div className="row">
                                                <div className="row">
                                                    <img src={(this.state.step>1) ? step1Filled :  step1UnFilled} width="105" style={{ "marginTop": "30px"}} />

                                                </div>
                                            </div>
                                        )}
                                    </Step>
                                    <Step>
                                        {() => (
                                            <div>
                                                <img src={(this.state.step>2) ? step2Filled : step2UnFilled} width="105" style={{ "marginTop": "30px" }} />

                                            </div>
                                        )}
                                    </Step>
                                </ProgressBar>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col form-container">
                                <div>
                                    <MaterialStyles/>

                                    <div className="d-none d-sm-block text-center">
                                        <div style={{"display": `${(this.state.step === 0) ? "block" : "none"}`}}>
                                            <h4><p style={{"fontWeight": "300", "fontSize": "1.2rem"}}>
                                                <span className="pagdividido-pink">Compre agora e pague depois.</span><br />
                                                Para fazer a simulação gratuita de empréstimo, você precisa preencher suas informações na <span className="pagdividido-pink">PagDividido.</span>
                                            </p>
                                            </h4>
                                        </div>
                                    </div>

                                    {/* -------------------------------- INITIAL SCREEN -------------------------------- */}
                                    <div className="text-center"
                                         style={{"display": `${(this.state.step === 0 && this.state.approved === false && this.state.denied === false) ? "block" : "none"}`}}>
                                        <form>
                                            <div className="row">
                                                <TextField
                                                    type="text"
                                                    name="name"
                                                    id="nameField"
                                                    label="Nome Completo"
                                                    value={this.state.name}
                                                    onChange={this.handleInputChange} />
                                            </div>
                                            <div className="row">
                                                <TextField
                                                    type="text"
                                                    name="email"
                                                    id="emailField"
                                                    label="Email"
                                                    value={this.state.email}
                                                    onChange={this.handleInputChange} />
                                            </div>
                                            <div className="row">
                                                <CellphoneField
                                                    onChange={this.handleInputChange}
                                                    name="cellphone"
                                                    id="cellphoneField"
                                                    label="Telefone celular"
                                                    value={this.state.cellphone} />

                                            </div>
                                            <div className="row" >
                                                <div className="col align-self-center">
                                                    <h6> <span className="pagdividido-blue">Obs.:</span> O valor do empréstimo, caso seja concedido, será creditado em sua conta corrente (fornecida durante o processo de avaliação).</h6>
                                                    <div style={{"lineHeight": ".2em"}}>
                                                        <FormControlLabel className="mr-0"
                                                                          control={
                                                                              <Checkbox
                                                                                  checked={this.state.optin}
                                                                                  name="optin"
                                                                                  onChange={this.handleInputChange}
                                                                                  value={this.state.optin}
                                                                                  color="default" />}
                                                        /> <span style={{ "fontSize": "0.75rem" }} >Li e concordo com os
                                                    <span onClick={this.handleToggleModal} style={{ "cursor": "pointer" }} className="pagdividido-blue"> Termos de Serviço e Política de Privacidade</span>.</span>

                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.inputError !== "" && <ErrorMessage message={this.state.inputError} />}

                                            <div ref={(ref)=> this.recaptcha = ref}
                                                 style={{"display": `${this.state.captchaOk? "none": "block"}`}}
                                                 className={`g-recaptcha disabledCaptcha 
                                                 ${this.state.cellphone.match(/[(][1-9]{2}[)] 9[1-9]\d{3}-\d{4}/)? "activatedCaptcha": ""}`}
                                            />


                                            {this.state.captchaOk &&
                                            <button
                                                type="button"
                                                onClick={this.nextStep}
                                                className="row align-content-between next-button">
                                                <div style={{"margin": "auto"}}>
                                                    <span>CONTINUAR</span>
                                                </div>
                                            </button>}

                                            <div className="row mt-3 mb-5">
                                                <h6 className="text-justify">
                                                    Por favor preencha seus dados da mesma forma que estão em seus
                                                    documentos. As informações fornecidas serão usadas para verificar
                                                    sua identidade e possibilidade de crédito.
                                                </h6>
                                            </div>

                                        </form>
                                    </div>

                                    {/* -------------------------------- SMS VERIFY -------------------------------- */}
                                    <div className="text-center"
                                         style={{"display": `${(this.state.step === 1 && this.state.approved === false && this.state.denied === false) ? "block" : "none"}`}}>

                                        <div className="row">
                                            <p className="text-justify">
                                                Um código de verificação foi enviado por SMS para o seu celular. Digite-o abaixo e continue para o próximo passo.
                                            </p>

                                            <TextField
                                                type="text"
                                                name="smsVerificationCode"
                                                id="smsVerificationCodeField"
                                                label="Código de verificação"
                                                value={this.state.smsVerificationCode}
                                                onChange={this.handleInputChange}/>

                                            <button
                                                type="button"
                                                onClick={this.verifySMS}
                                                className="row align-content-between next-button">
                                                <div style={{"margin": "auto"}}>
                                                    <span>CONTINUAR</span>
                                                </div>
                                            </button>

                                            {!this.state.smsReload &&
                                            <button
                                                type="button"
                                                onClick={this.resendCaptcha}
                                                className="row align-content-between resend-button">
                                                <div style={{"margin": "auto"}}>
                                                    <span>REENVIAR</span>
                                                </div>
                                            </button>}

                                            {this.state.smsReload &&
                                            <button
                                                type="button"
                                                onClick={this.reloadPage}
                                                className="row align-content-between resend-button">
                                                <div style={{"margin": "auto"}}>
                                                    <span>VOLTAR</span>
                                                </div>
                                            </button>}

                                            <div ref={(ref)=> this.invisibleRecaptcha = ref}/>

                                            <div className="w-100">
                                                <img src={spinner} style={{display: "none", scale: "0.8", margin: "auto"}} ref={(ref)=> this.loader = ref} alt="spinner"/>
                                            </div>

                                        </div>

                                        {this.state.inputError !== "" && <ErrorMessage message={this.state.inputError} />}
                                    </div>

                                    {/* ------------------------------------ FORMS ------------------------------------- */}
                                    <div style={{"display": `${((this.state.approved === false && this.state.denied === false)) ? "block" : "none"}`}}>
                                        <form onSubmit={this.nextStep}
                                              style={{"display": `${(this.state.step === 2) ? "block" : "none"}`}}>
                                            <TextField
                                                onChange={this.handleInputChange}
                                                type="text"
                                                name="cpf"
                                                id="cpfField"
                                                label="CPF (Somente Números)"
                                                pattern="[0-9]*"
                                                disabled={this.state.preCpf}
                                                value={this.state.taxDocument.number || ''}/>

                                            <DateField
                                                onChange={this.handleInputChange}
                                                name="birthdate"
                                                id="birthdateField"
                                                label="Data de Nascimento"
                                                value={this.state.birthdate}/>

                                            <div ref={(ref)=> this.rgRef = ref}>
                                                <TextField
                                                    type="tel"
                                                    name="rg"
                                                    id="rgField"
                                                    label="RG (Somente Números)"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    value={this.state.rg}
                                                    onChange={this.handleInputChange}/>
                                            </div>

                                            <TextField
                                                select
                                                native
                                                data={options.genderOptions}
                                                name="gender"
                                                id="genderField"
                                                label="Sexo"
                                                value={this.state.gender}
                                                onChange={this.handleInputChange}/>

                                            <div ref={(ref)=> this.motherNameRef = ref}>
                                                <TextField
                                                    type="text"
                                                    name="motherName"
                                                    id="motherField"
                                                    label="Nome da mãe"
                                                    value={this.state.motherName}
                                                    onChange={this.handleInputChange}/>
                                            </div>

                                            {this.state.inputError !== "" && <ErrorMessage message={this.state.inputError}/>}

                                            <button type="submit"
                                                    style={{
                                                        "width": `${isMobile ? "100%" : "400px"}`
                                                    }}
                                                    className="row align-content-between next-button">
                                                <div style={{"margin": "auto"}}>
                                                    <span>CONTINUAR</span>
                                                </div>
                                            </button>

                                            <div className="row mt-3 mb-5">
                                                <h6 className="text-justify">
                                                    Por favor preencha seus dados da mesma forma que estão em seus
                                                    documentos. As informações fornecidas serão usadas para verificar
                                                    sua identidade e possibilidade de crédito.
                                                </h6>
                                            </div>

                                        </form>

                                        <form onSubmit={this.sendForm}
                                              style={{"display": `${(this.state.step === 3) ? "block" : "none"}`}}>
                                            <p className="pagdividido-pink mt-sm-2 mt-0 ml-2"
                                               style={{"fontSize": "1.5rem"}}>
                                                Endereço
                                            </p>

                                            <CepField
                                                onBlur={this.handleCepInput}
                                                name="zipCode"
                                                id="zipField"
                                                label="CEP"
                                                value={this.state.zipCode}/>

                                            <TextField
                                                type="text"
                                                name="address"
                                                id="addressField"
                                                label="Endereço"
                                                value={this.state.address}
                                                onChange={this.handleInputChange}/>

                                            <TextField
                                                type="tel"
                                                name="number"
                                                id="numberField"
                                                label="Número"
                                                value={this.state.number}
                                                onChange={this.handleInputChange}/>

                                            <TextField
                                                type="text"
                                                name="complement"
                                                id="complementField"
                                                label="Complemento"
                                                value={this.state.complement}
                                                onChange={this.handleInputChange}/>

                                            <TextField
                                                type="text"
                                                name="neighborhood"
                                                id="neighborhoodField"
                                                label="Bairro"
                                                value={this.state.neighborhood}
                                                onChange={this.handleInputChange}/>

                                            <TextField
                                                select
                                                native
                                                data={options.stateOptions}
                                                name="state"
                                                id="stateField"
                                                label="Estado"
                                                value={this.state.state}
                                                onChange={this.handleInputChange}/>

                                            <TextField
                                                type="text"
                                                name="city"
                                                id="cityField"
                                                label="Cidade"
                                                value={this.state.city}
                                                onChange={this.handleInputChange}/>

                                            <TextField
                                                select
                                                native
                                                data={options.stateOptions}
                                                name="birthState"
                                                id="birthStateField"
                                                label="Estado de nascimento"
                                                value={this.state.birthState}
                                                onChange={this.handleInputChange}/>

                                            <TextField
                                                type="text"
                                                name="birthCity"
                                                id="birthCityField"
                                                label="Cidade de nascimento"
                                                value={this.state.birthCity}
                                                onChange={this.handleInputChange}/>

                                            {this.state.inputError !== "" && <ErrorMessage message={this.state.inputError}/>}

                                            <button type="button"
                                                    className="previous-button"
                                                    onClick={this.previousStep}
                                                    style={{"width": `${isMobile ? "100%" : "400px"}`}}>
                                                VOLTAR
                                            </button>

                                            <button type="submit"
                                                    className="row align-content-between next-button"
                                                    style={{
                                                        "width": `${isMobile ? "100%" : "400px"}`
                                                    }}>
                                                <div style={{"margin": "auto"}}>
                                                    <span>SOLICITAR</span>
                                                </div>
                                            </button>
                                            <div className="row mt-3 mb-5">
                                                <h6 className="text-justify">
                                                    Por favor preencha seus dados da mesma forma que estão em seus
                                                    documentos. As informações fornecidas serão usadas para verificar
                                                    sua identidade e possibilidade de crédito.
                                                </h6>
                                            </div>
                                        </form>

                                        {/*<form onSubmit={this.sendForm}
                                               style={{"display": `${(this.state.step === 5) ? "block" : "none"}`}}>

                                            <p className="pagdividido-pink mt-sm-2 mt-0 ml-2"
                                               style={{"fontSize": "1.5rem"}}>
                                                Dados Bancários
                                            </p>

                                            <TextField
                                                select
                                                native
                                                data={options.bankOptions}
                                                name="bank"
                                                id="bankField"
                                                label="Banco"
                                                value={this.state.bank}
                                                onChange={this.handleInputChange}/>

                                            <div
                                                style={{"display": `${(this.state.bank === "104" || this.state.bank === "237") ? "block" : "none"}`}}>

                                                <TextField
                                                    select
                                                    native
                                                    data={options.accountTypeOptions}
                                                    name="accountType"
                                                    id="accountTypeField"
                                                    label="Tipo"
                                                    value={this.state.accountType}
                                                    onChange={this.handleInputChange}/>
                                            </div>

                                            <TextField
                                                type="tel"
                                                name="bankBranch"
                                                id="brankBranchField"
                                                maxLength="10"
                                                label="Agência"
                                                value={this.state.bankBranch}
                                                onChange={this.handleInputChange}/>

                                            <TextField
                                                type="tel"
                                                name="branchDigit"
                                                id="branchDigitField"
                                                label="Dígito da agência"
                                                maxLength="1"
                                                value={this.state.branchDigit}
                                                onChange={this.handleInputChange}/>

                                            <TextField
                                                type="tel"
                                                name="accountNumber"
                                                id="accountNumberField"
                                                label="Conta"
                                                value={this.state.accountNumber}
                                                onChange={this.handleInputChange}/>

                                            <TextField
                                                type="tel"
                                                name="accountDigit"
                                                id="accountDigitField"
                                                maxLength="1"
                                                label="Dígito da conta"
                                                value={this.state.accountDigit}
                                                onChange={this.handleInputChange}/>

                                            <TextField
                                                type="tel"
                                                name="payday"
                                                id="paydayField"
                                                maxLength="2"
                                                label="Melhor dia para pagamento"
                                                value={this.state.payday}
                                                onChange={this.handleInputChange}
                                                style={{marginBottom: `${this.state.inputError !== "" ? "1rem" : "0"}`}}/>

                                            {this.state.inputError !== "" &&
                                            <ErrorMessage message={this.state.inputError}/>}

                                            <button type="button"
                                                    className="previous-button"
                                                    onClick={this.previousStep}
                                                    style={{"width": `${isMobile ? "100%" : "400px"}`}}>
                                                VOLTAR
                                            </button>

                                            <button type="submit"
                                                    className="row align-content-between next-button"
                                                    style={{
                                                        "width": `${isMobile ? "100%" : "400px"}`
                                                    }}>
                                                <div style={{"margin": "auto"}}>
                                                    <span>SOLICITAR</span>
                                                </div>
                                            </button>
                                            <div className="row mt-3 mb-5">
                                                <h6 className="text-justify">
                                                    Por favor preencha seus dados da mesma forma que estão em seus
                                                    documentos. As informações fornecidas serão usadas para verificar
                                                    sua identidade e possibilidade de crédito.
                                                </h6>
                                            </div>
                                        </form>*/}
                                    </div>

                                    {/* ----------------------------------- APPROVED ----------------------------------- */}
                                    <div
                                        style={{"display": `${((this.state.approved === true && this.state.captureApproved === false && this.state.denied === false)) ? "block" : "none"}`}}>
                                        <div className="row">
                                            <div className="col align-self-center">
                                                <p className="text-justify">
                                                    <span className="pagdividido-pink">{this.state.firstname}</span>, temos boas notícias para você.<br/>
                                                        Você conseguiu um crédito pré-aprovado com nossos parceiros.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col align-self-center">
                                                <p>Clique na opção de sua escolha e continue sua análise de crédito em um de nossos parceiros Premium.</p>
                                            </div>
                                        </div>
                                        <div className="row offer-container">
                                            <div className="col align-self-center">
                                                <OfferCard response={this.state.checkoutResponse} confirmContractAndRedirect={this.confirmContractAndRedirect}/>
                                            </div>
                                        </div>

                                        <h6>
                                            Obs.: É importante lembrar que, caso o seu pedido seja pré-aprovado, ele ainda passará por uma segunda avaliação do banco ou financeira em questão. Mas, não se preocupe, o processo tende a ser bem rápido e positivo. Após a assinatura do contrato, o dinheiro deve cair na sua conta em até 24 horas úteis.
                                        </h6>
                                    </div>

                                    {/* ----------------------------------- CAPTURE APPROVED ----------------------------------- */}
                                    <div
                                        style={{"display": `${((this.state.captureApproved === true && this.state.denied === false)) ? "block" : "none"}`}}>
                                        <div className="row">
                                            <div className="col align-self-center">
                                                <p className="text-justify">
                                                    <span className="pagdividido-pink">{this.state.firstname}</span>,<br/>
                                                    Agora você receberá um SMS com instruções do nosso parceiro para finalizar seu empréstimo.
                                                    Você está a poucos passos de receber seu crédito!
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ------------------------------------ DENIED ------------------------------------ */}
                                    <div
                                        style={{"display": `${((this.state.approved === false && this.state.denied === true)) ? "block" : "none"}`}}>
                                        <div className="row" style={{"marginTop": `${isMobile ? "0" : "70px"}`}}>
                                            <div className="col align-self-center mb-5" >
                                                <p className="text-justify">
                                                    <span style={{ "textTransform": "capitalize" }}> {this.state.name || "Olá"}</span>, infelizmente neste momento não conseguimos realizar seu pedido de empréstimo em nenhum de nossos parceiros. Nossos parceiros recomendam tentar novamente em 6 meses.
                                                </p>
                                                <p>
                                                    Atenção! A PagDividido não solicita depósito antecipado para a liberação do empréstimo. Isso é crime.
                                                </p>
                                                <p className="text-center">
                                                    <span className="pagdividido-pink text-center">Alguns motivos do porquê não foi possível conceder um empréstimo</span>
                                                </p>
                                                <ul className="p-0 text-justify">
                                                    <li>Lembre-se, para manter uma boa saúde financeira é importante que o valor da parcela comprometa, apenas, até 30% da sua renda mensal.</li>
                                                    <li>Verifique se não há parcelas em atraso em outras instituições.</li>
                                                    <li>Confira se o seu CPF está negativado ("Nome Sujo"). Você pode consultar essa informação&nbsp;
                                                        <a href="https://www.serasaconsumidor.com.br/consultar-meu-cpf/">AQUI</a>.
                                                    </li>
                                                    <li>Como está o seu histórico financeiro? Se você paga as contas sempre em atraso isso pode impactar a chance de você conseguir crédito no mercado financeiro.</li>
                                                    <li>Para uma explicação mais detalhada, fizemos uma&nbsp;
                                                        <a href="https://www.pagdividido.com.br/razoes">LISTA DOS POSSÍVEIS MOTIVOS DA NÃO APROVAÇÃO DO PEDIDO.</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ "display": `${((isMobile)||(this.state.step > 0)) ? "none" : "block"}` }}>
                    <Footer/>
                </div>
            </LoadingScreen>
        );
    }
}

export default ClientPage
