import React from 'react'

function SimpleOfferCard(props) {
    const offerArray = props.offers;
    const offerItems = [];
    var colItems = [];

    if (typeof offerArray == 'undefined') {
        return(<div></div>)
    }
    for (var i = 0; i < offerArray.length; i++) {

        let amount = offerArray[i].totalFinancedValue;
        amount = amount.toFixed(2).toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        colItems.push(

                <div className="offer-card mb-3" key={i}>
                  <div className="row">
                    <div className="col-12 h3">
                      R$ {amount}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <span>
                        {offerArray[i].offerDescription}
                      </span>
                    </div>
                  </div>
                </div>
        );
    }


    return (
        <div className="row justify-content-center">
            {colItems}
        </div>
    );
}

export default SimpleOfferCard