import React, { Component } from 'react';
import "../css/Footer.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import iconLupa from "../images/img-lupa.png"
import iconSetaBaixo from "../images/seta-baixo.png"
import iconWallet from "../images/icon-wallet.png"
import iconStar from "../images/icon-star.png"
import arrowNext from "../images/seta-next.png"
import arrowPrevious from "../images/seta-previous.png"
import Slider from "react-slick";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img src={arrowNext} alt="Star" width="30px" className={className} style={{ ...style, display: "block"}}
            onClick={onClick} />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img src={arrowPrevious} alt="Star" width="30px" className={className} style={{ ...style, display: "block" }}
            onClick={onClick} />
    );
}

class Footer extends Component {

    render() {

        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: <SamplePrevArrow />,
            nextArrow: <SampleNextArrow />
        };

        return (
            <div className="Footer" style={{marginBottom: "-16px"}}>

                <section className="footer-area" id="about">

                    <div className="row justify-content-center">
                        <div className="col text-center slider-container mb-5">
                            <Slider {...settings}>
                                <div className="text-center">
                                    <h4>Excelente alternativa de crédito para compras online!</h4>
                                    <span className="pagdividido-blue">Rogerio C.</span><br />
                                    <span className="font-weight-bold">Advogado</span><br />
                                    <div className="row justify-content-center">
                                        <div className="col">
                                            <img src={iconStar} alt="Star" width="30px" />
                                            <img src={iconStar} alt="Star" width="30px" />
                                            <img src={iconStar} alt="Star" width="30px" />
                                            <img src={iconStar} alt="Star" width="30px" />
                                            <img src={iconStar} alt="Star" width="30px" />
                                        </div>
                                    </div>

                                </div>
                                    <div className="text-center">
                                        <h4>Solução diferenciada. Funciona super bem.</h4>
                                        <span className="pagdividido-blue">Renato P.</span><br />
                                        <span className="font-weight-bold">Administrador de Empresas</span><br />
                                        <div className="row justify-content-center">
                                            <div className="col">
                                                <img src={iconStar} alt="Star" width="30px" />
                                                <img src={iconStar} alt="Star" width="30px" />
                                                <img src={iconStar} alt="Star" width="30px" />
                                                <img src={iconStar} alt="Star" width="30px" />
                                                <img src={iconStar} alt="Star" width="30px" />
                                            </div>
                                        </div>
                                    </div>

                                        <div className="text-center">
                                            <h4>
                                                Me ajudou quando eu precisei de crédito para uma compra online</h4>
                                            <span className="pagdividido-blue">Guilherme M.</span><br />
                                            <span className="font-weight-bold">Sistema de Informação</span><br />
                                            <div className="row justify-content-center">
                                                <div className="col">
                                                    <img src={iconStar} alt="Star" width="30px" />
                                                    <img src={iconStar} alt="Star" width="30px" />
                                                    <img src={iconStar} alt="Star" width="30px" />
                                                    <img src={iconStar} alt="Star" width="30px" />
                                                    <img src={iconStar} alt="Star" width="30px" />
                                                </div>
                                            </div>
                                            </div>
                            </Slider>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col footer-container ml-5 mr-5 mb-3">
                            <div className="row mt-3 mb-3">
                                <div className="col text-center">
                                    <h4><span className="text-pink">As vantagens de usar o Crédito Digital na PagDividido</span></h4>

                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-4 text-center">
                                    <img src={iconLupa} alt="Análise" /><p/>
                                    <span className="text-pink"> Seu pedido é analisado e pode ser pré-aprovado por até 5 instituições financeiras de uma vez! </span>
                                </div>
                                <div className="col-4 text-center">
                                    <img src={iconSetaBaixo} alt="Menores taxas" /><p/>
                                    <span className="text-pink"> A PagDividido te ajuda a encontrar as melhores taxas de juros e parcelas que cabem no seu bolso. </span>
                                </div>
                                <div className="col-4  text-center">
                                    <img src={iconWallet} alt="Ofertas em minutos" /><p/>
                                    <span className="text-pink">  Nova opção para parcelar as compras. </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col ml-3 mr-3">
                                    <h5> As taxas de juros dos nossos parceiros começam 1,9% ao mês até 18% ao mês. Elas variam de acordo com a linha de crédito, a instituição financeira e o perfil do cliente. Lembre-se de que, quando maior o risco de inadimplência, ou seja, de você não pagar o que deve, maiores as taxas cobradas.
                                    </h5>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mb-2">
                        <div className="col text-center">
                            SAC: <span className="text-blue">atendimento@pagdividido.com.br</span>
                        </div>
                    </div>
                    <div style={{backgroundColor:"#595959"}} className="row p-2 bg-dark">
                        <div className="col ml-3 mr-3 text-center">
                            <img src="https://moneri.s3-sa-east-1.amazonaws.com/Pagdividido/Imagens/icone-white.png" alt="Logo PagDividido" style={{maxWidth: "175px", width: "100px", height:"auto", marginBottom: "0.5rem"}}/>
                            <h5 className="text-white">PAG DIVIDIDO TECNOLOGIA LTDA. - CNPJ 32.583.202/0001-08</h5>
                        </div>
                    </div>

                </section>
            </div>
        );
    }
}

export default Footer